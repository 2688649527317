import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavMenuItem } from '@fsco-components/core/menu/navigation-menu/navigation-menu.model';


export type Submenu = {
    items: SubmenuItemType[];
    cta?: SubmenuCta; // Call to Action
}

/**
 * @description Type of items inside of submenu list
 */
export type SubmenuItemType =
    | SubmenuTitle
    | SubmenuTitleAvatar
    | SubmenuSection
    | SubmenuDivider
    | SubmenuTitleAccount;

/**
 * @description Options extended for submenu
 * Cta: Call to Action
 */
export type SubmenuCta = {
    label: string;
    items?: NavMenuItem[];
};

export class SubmenuTitle {
    header?: string;
    icon?: IconProp;

    constructor(data: Partial<SubmenuTitle>) {
        Object.assign(this, data);
    }
}

export class SubmenuTitleAvatar {
    header: string = '';
    subheader?: string;
    background?: string;

    constructor(data: Partial<SubmenuTitleAvatar>) {
        Object.assign(this, data);
    }
}

export class SubmenuTitleAccount {
    header: string = '';
    subheader?: string;
    icon?: IconProp;

    constructor(data: Partial<SubmenuTitleAccount>) {
        Object.assign(this, data);
    }
}

export class SubmenuSection {
    label?: string;
    icon?: IconProp;
    link?: string[] | string;
    linkParams?: { [key: string]: string };
    exact?: boolean;
    indent?: boolean;
    items?: SubmenuItem[];

    constructor(data: Partial<SubmenuSection>) {
        Object.assign(this, data);
    }
}

export class SubmenuItem {
    label?: string;
    icon?: IconProp;
    link?: string[] | string;
    subItems?: SubmenuItem[];
    constructor(data: Partial<SubmenuItem>) {
        Object.assign(this, data);
    }
}

export class SubmenuDivider {
    header?: string;
    icon?: IconProp;
    constructor(data?: Partial<SubmenuTitle>) {
        Object.assign(this, data);
    }
}

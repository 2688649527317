import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastPositionType } from 'primeng/toast';

@Component({
    selector: 'fsco-message-inline',
    templateUrl: './message-inline.component.html',
    styleUrls: ['./message-inline.component.scss'],
})
export class MessageInlineComponent {
    @Input() position: ToastPositionType = 'bottom-right';
    @Input() key: string = '';
    @Input() preventDuplicates: boolean = false;
    @Input() life: number = 8000;
}

export const SECOND_IN_MILLISECONDS = 1000;
export const MINUTE_IN_SECONDS = 60;
export const MINUTE_IN_MILLISECONDS = 60 * 1000;
export const HOUR_IN_MINUTES = 60;
export const HOUR_IN_SECONDS = HOUR_IN_MINUTES * MINUTE_IN_SECONDS;
export const HOUR_IN_MILLISECONDS = HOUR_IN_SECONDS * SECOND_IN_MILLISECONDS;
export const DAY_IN_HOURS = 24;
export const DAY_IN_MINUTES = DAY_IN_HOURS * HOUR_IN_MINUTES;
export const DAY_IN_SECONDS = DAY_IN_HOURS * HOUR_IN_SECONDS;
export const DAY_IN_MILLISECONDS = DAY_IN_HOURS * HOUR_IN_MILLISECONDS;

export const MIN_CHAR_SEARCH = 3;
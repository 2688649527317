/**
 * Webhooks Outbound Portal API
 * This specifies the Webhook Outbound endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { UpdateWebhookOutboundRequestContext } from './updateWebhookOutboundRequestContext';
import type { UpdateWebhookOutboundRequestRequest } from './updateWebhookOutboundRequestRequest';

export class UpdateWebhookOutboundRequest {
    /**
    * The unique identifier of the outbound webhook
    */
    'id': string;
    /**
    * The type of the outbound webhook
    */
    'type': UpdateWebhookOutboundRequest.TypeEnum = UpdateWebhookOutboundRequest.TypeEnum.External;
    /**
    * The subscriptions for the outbound webhook
    */
    'subscriptions': Array<string>;
    /**
    * The organisation ID associated with the outbound webhook
    */
    'organisationId': string;
    'request': UpdateWebhookOutboundRequestRequest;
    'context': UpdateWebhookOutboundRequestContext;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "UpdateWebhookOutboundRequest.TypeEnum"
        },
        {
            "name": "subscriptions",
            "baseName": "subscriptions",
            "type": "Array<string>"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "string"
        },
        {
            "name": "request",
            "baseName": "request",
            "type": "UpdateWebhookOutboundRequestRequest"
        },
        {
            "name": "context",
            "baseName": "context",
            "type": "UpdateWebhookOutboundRequestContext"
        }    ];

    static getAttributeTypeMap() {
        return UpdateWebhookOutboundRequest.attributeTypeMap;
    }
}

export namespace UpdateWebhookOutboundRequest {
    export enum TypeEnum {
        External = <any> 'external'
    }
}

import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { IStepHandler } from './step-handler.model';
import { IStep } from './step.model';
import { Subscription } from 'rxjs';

/**
 * StepHandlerComponent manages the navigation between different steps in a process.
 * It emits events on step changes and handles the activation and deactivation of steps.
 */
@Injectable({
    providedIn: 'root',
})
export class StepHandlerService implements IStepHandler, OnDestroy {
    currentStep!: number;
    maxSteps!: number;
    public isNextEnabled: () => boolean = () => false;
    public isPrevEnabled: () => boolean = () => false;
    public onNextClick: () => boolean = () => true;
    public onPrevClick: () => boolean = () => true;

    private step!: IStep<any, any>;

    private stepChangedSubscription: Subscription | null = null;
    public stepChangedEmitter = new EventEmitter<{ currentStep: number; previousStep: number; isFinalAction: boolean }>();

    constructor() {
        this.currentStep = 1;
        this.maxSteps = 1;
    }

    ngOnDestroy(): void {
        this.unsubscribeStepChanged();
    }

    configure(maxSteps: number): void {
        this.maxSteps = maxSteps;
    }

    setStep(step: IStep<any, any>) {
        this.step = step;
        this.isNextEnabled = () => {
            if (this.step !== undefined) {
                return this.step.isNextEnabled();
            }
            return true;
        };
        this.isPrevEnabled = () => {
            if (this.step !== undefined) {
                return this.step.isPrevEnabled();
            }
            return true;
        };
        this.onNextClick = () => {
            if (this.step !== undefined && this.step.onNextClick()) {
                this.goToNextStep();
                return true;
            }
            return false;
        };
        this.onPrevClick = () => {
            if (this.step !== undefined && this.step.onPrevClick()) {
                this.goToPreviousStep();
                return true;
            }
            return false;
        };
    }

    setCurrentStep(step: number): void {
        this.currentStep = step;
    }

    public goToNextStep() {
        const prevStep = this.currentStep;
        const isFinalAction = !(this.currentStep < this.maxSteps);
        if (!isFinalAction) {
            ++this.currentStep;
        }
        this.stepChangedEmitter.emit({ currentStep: this.currentStep, previousStep: prevStep, isFinalAction: isFinalAction });
    }

    public goToPreviousStep() {
        const previousStep = this.currentStep;
        if (this.currentStep > 1) {
            --this.currentStep;
            this.stepChangedEmitter.emit({
                currentStep: this.currentStep,
                previousStep: previousStep,
                isFinalAction: false,
            });
        }
    }

    getCurrentStep(): number {
        return this.currentStep;
    }

    getMaxSteps(): number {
        return this.maxSteps;
    }

    getStep(): IStep<any, any> {
        return this.step;
    }

    getData(): void {
        return this.step.getData();
    }

    onStepChanged(
        listener: ({
            currentStep,
            previousStep,
            isFinalAction,
        }: {
            currentStep: number;
            previousStep: number;
            isFinalAction: boolean;
        }) => void,
    ): void {
        this.unsubscribeStepChanged();
        this.stepChangedSubscription = this.stepChangedEmitter.subscribe(listener);
    }

    private unsubscribeStepChanged(): void {
        if (this.stepChangedSubscription) {
            this.stepChangedSubscription.unsubscribe();
            this.stepChangedSubscription = null;
        }
    }
}

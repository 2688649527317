import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'fsco-skeleton-list',
    templateUrl: './skeleton-list.component.html',
    styleUrls: ['./skeleton-list.component.scss'],
})
export class SkeletonListComponent implements OnChanges {
    @Input() rows: number = 1;
    @Input() size: 'sm' | 'md' = 'md';
    rowsItems!: any[];

    constructor() {
        this.buildItems();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.rows || changes.size) {
            this.buildItems();
        }
    }

    buildItems() {
        this.rowsItems = Array(this.rows).fill({});
    }

}

<div class="px-5 pt-6">
    <div class="flex mb-3">
        <div class="col-9">
            <div class="grid gap-2">
                <div class="col-1">
                    <!-- Icon -->
                    <p-skeleton shape="circle" size="4rem" styleClass="mr-2"></p-skeleton>
                </div>
                <div class="col-8 ml-2">
                    <!-- Name -->
                    <p-skeleton width="8rem" styleClass="mb-2 ml-2"></p-skeleton>
                    <!-- Last Updated -->
                    <p-skeleton width="8rem" styleClass="mb-2 ml-2"></p-skeleton>
                    <!-- Actions -->
                    <p-skeleton width="8rem" styleClass="mb-2 ml-2"></p-skeleton>
                </div>
            </div>
        </div>
        <div class="col-1">
            <!-- Download Icon -->
            <p-skeleton width="2rem" styleClass="mb-2 ml-2"></p-skeleton>
        </div>
        <div class="col-2 align-content-end">
            <!-- Ledger Button -->
            <p-skeleton width="5rem" styleClass="mb-2 ml-2"></p-skeleton>
        </div>
    </div>
    <div class="flex justify-content-start mt-3 mb-3">
        <!-- Text -->
        <p-skeleton class="mr-2" width="4rem" height="2rem"></p-skeleton>
        <p-skeleton width="4rem" height="2rem"></p-skeleton>
    </div>
    <!-- Content -->
    <p-skeleton width="100%" height="150px"></p-skeleton>
</div>

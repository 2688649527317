import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { faTachometerAlt, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { LayoutService } from '@shared/services/layout.service';
import { Subject } from 'rxjs';
import { NetworkInformation } from './connection-status.model';

@Component({
    selector: 'fsco-header-connection-status',
    templateUrl: './connection-status.component.html',
    styleUrls: ['./connection-status.component.scss'],
})
export class HeaderConnectionStatusComponent implements OnInit, OnDestroy {
    faWifiSlash = faWifiSlash;
    faTachometerAlt = faTachometerAlt;
    isOffline = !navigator.onLine;
    isSlowConnection = false;

    private destroy$ = new Subject<void>();

    constructor(
        private ngZone: NgZone,
        private layoutService: LayoutService,
    ) {}

    ngOnInit() {
        this.setupEventListeners();
        this.checkConnectionSpeed();
        setTimeout(() => {
            this.layoutService.updateShowTopBarStatus(true);
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.removeEventListeners();
        this.layoutService.updateShowTopBarStatus(false);
    }

    private setupEventListeners() {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('online', this.handleConnectionChange);
            window.addEventListener('offline', this.handleConnectionChange);

            if ('connection' in navigator && 'addEventListener' in (navigator as any).connection) {
                (navigator as any).connection.addEventListener(
                    'change',
                    this.handleConnectionChange,
                );
            }
        });
    }

    private removeEventListeners() {
        window.removeEventListener('online', this.handleConnectionChange);
        window.removeEventListener('offline', this.handleConnectionChange);

        if ('connection' in navigator && 'removeEventListener' in (navigator as any).connection) {
            (navigator as any).connection.removeEventListener(
                'change',
                this.handleConnectionChange,
            );
        }
    }

    private handleConnectionChange = () => {
        this.ngZone.run(() => {
            this.isOffline = !navigator.onLine;
            this.checkConnectionSpeed();
        });
    };

    private checkConnectionSpeed() {
        if ('connection' in navigator) {
            const connection = (navigator as any).connection as NetworkInformation;
            // Consider connection slow if downlink is less than 1 Mbps
            this.isSlowConnection = connection.downlink < 1;
        }
    }
}

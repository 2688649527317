import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_PATHS } from '@shared/navigation.service';
import { CARD_DATA } from './card-data';
import { CardData } from './card-image.model';
@Component({
    selector: 'fsco-card-image',
    templateUrl: './card-image.component.html',
    styleUrls: ['./card-image.component.scss'],
})
export class CardImageComponent implements OnInit {
    @Output() navigateRequest = new EventEmitter<string>();

    cards: CardData[] = [];

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.cards = CARD_DATA;
    }

    handleButtonClick(route: string) {
        this.navigateRequest.emit(route);
    }

    getClassesFor(brakePoints: string, type: 'parent' | 'child' | 'img' | 'content'): string {
        return `lg-break-${brakePoints}-${type} md-break-${brakePoints}-${type} sm-break-${brakePoints}-${type}`;
    }
}

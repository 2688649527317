import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Menu } from 'primeng/menu';

@Component({
    selector: 'fsco-card-details',
    templateUrl: './card-details.component.html',
    styleUrls: ['./card-details.component.scss'],
})
export class CardDetailsComponent implements OnInit, OnDestroy {
    @Input() createMode: boolean = false;
    @Input() title: string = '';
    @Input() subtitle: string = '';
    @Input() body: string = '';
    @Input() icon: IconProp = faPlus;
    @Input() iconColor: string = 'gray-200';
    @Input() submenuItems: MenuItem[] = [];
    @Input() isNew: boolean = false;
    @Input() isUpdating: boolean = false;
    @Input() createButton = true;
    @Output() onMainActionClick = new EventEmitter<void>();
    @Output() onSecondaryActionClick = new EventEmitter<void>();

    @ViewChild('menuContext') menuContext!: ContextMenu;
    iconEllipsis: IconProp = faEllipsisV;

    ngOnInit() {}

    ngOnDestroy() {}

    handleMainClick() {
        this.onMainActionClick.emit();
    }

    handleSecondaryClick() {
        this.onSecondaryActionClick.emit();
    }

    /**
     * Open the context menu.
     * @description This method opens the context menu.
     * @param event - The event that triggered the menu opening.
     */
    openContextMenu(event: Event) {
        this.menuContext.show(event);
    }
}

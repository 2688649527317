import type { IconProp } from "@fsco/fontawesome-pro/fontawesome-svg-core";

export interface NavMenuItem {
    label: string;
    icon?: IconProp;
    command?: (event?: any) => void;
    routerLink?: string;
    items?: NavMenuItem[];
    expanded?: boolean;
  }

export enum PositionVertical {
    Above = 'above',
    Below = 'below'
}

export enum PositionHorizontal {
    Left = 'left',
    Right = 'right'
}




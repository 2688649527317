<div class="fixed w-full z-1 left-0 top-0">
    <fsco-header-connection-status></fsco-header-connection-status>
    <fsco-header-dev-warning></fsco-header-dev-warning>
    <div class="flex flex-column">
        <div class="py-3 pr-5 pl-7 flex align-items-center justify-content-between relative lg:static header" [ngClass]="[ header.styles?.default?.backgroundColor, isMenuExpanded ? 'expanded' : 'collapsed']" style="height: 50px">
            <!-- Left column -->
            <div class="flex align-items-center">
                <div class="header-beta text-xs p-2-5 pb-1 pt-1 mr-2 border-round-xl font-medium">BETA</div>
            </div>

            <!-- Right column -->
            <div class="flex align-items-center">

                <!-- Account -->
                <div class="header-avatar" *ngIf="orgLogoUrl" [style.background-color]="orgCustomColor">
                    <div class="header-avatar-inner" [style.background-image]="'url(' + orgLogoUrl + ')'"></div>
                </div>
                <div class="header-title white-space-nowrap text-overflow-ellipsis max-w-20rem w-auto">{{ orgName || header.title }}</div>

                <!-- Actions -->
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex lg:static ml-3">
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"></ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 lg:border-top-none">
                        <li *ngFor="let item of header.actions" (click)="onActionClicked(item.id)" class="cursor-pointer text-base hover:text-primary-900">
                            <div class="header-action-icon" *ngIf="item.type === 'icon'">
                                <a pRipple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-regular border-round cursor-pointer transition-colors transition-duration-150">
                                    <fa-stack>
                                        <fa-icon [icon]="faCircle" stackItemSize="2x" [ngClass]="header.styles?.default?.iconBackgroundColor"></fa-icon>
                                        <fa-icon *ngIf="item.icon" [icon]="item.icon" stackItemSize="1x" [ngClass]="header.styles?.default?.iconColor"></fa-icon>
                                    </fa-stack>
                                </a>
                            </div>
                            <div class="header-action-link" *ngIf="item.type === 'link'">
                                <fa-icon *ngIf="item.icon" [icon]="item.icon" class="mr-2"></fa-icon>
                                {{ item.label ?? '' | translate }}
                            </div>
                            <div class="header-action-dropdown" *ngIf="item.type === 'dropdown'">{{ item.label ?? '' | translate }}</div>
                        </li>
                    </ul>
                </div>

                <!-- Mobile menu button -->
                <a pRipple class="cursor-pointer block lg:hidden ml-3" pStyleClass="@next" enterFromClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
                    <i class="pi pi-bars text-4xl"></i>
                </a>
            </div>
        </div>
    </div>
</div>

import { EventTypeInfo } from '@shared/types/notifications';
import { EventTypeTest } from './_test';
import { EventTypeAsset } from './asset';
import { EventTypeDocument, EventTypeDocumentInfo } from './document';
import { EventType } from './global';
import { EventTypeMarketPlace } from './marketplace';
import { EventTypePayment } from './payment';

export const FSCO = {
    Test: EventTypeTest,
    Common: EventType,
    Payment: EventTypePayment,
    Asset: EventTypeAsset,
    Document: EventTypeDocument,
    MarketPlace: EventTypeMarketPlace,
};

/**
 * @description Type of all events that can be emitted by notifications system
 */
export type WebsocketEventType =
    | EventType
    | EventTypeTest
    | EventTypePayment
    | EventTypeAsset
    | EventTypeDocument
    | EventTypeMarketPlace;

/**
 * @description Type of all events that user can subscribe to
 */
export type WebhookEventType = EventTypeDocument;


/**
 * @description Add events that need extended info here
 */
export const EventTypeExtendedInfo = {
    ...EventTypeDocumentInfo,
};

/**
 * @description Get the extended info of an event
 * @param eventType 
 * @returns EventTypeInfo
 */
export function getEventTypeInfo(eventType: WebsocketEventType | string): EventTypeInfo | undefined {
    const info = EventTypeExtendedInfo[eventType as keyof typeof EventTypeExtendedInfo];
    if (!info) return undefined;
    return info;
}

import { Injectable } from '@angular/core';
import { WebsocketMessage } from '@portal-api-models/websocketMessage';
import { MINUTE_IN_MILLISECONDS } from '@shared/constants/global';
import { LoggerService } from '@shared/log.service';
import { WebsocketEventType } from '@shared/notifications';
import { Observable, Observer } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { environment } from '../../../environments/environment';

/**
 * @description
 * Service responsible for managing WebSocket connections.
 *
 * The `connectionAttempts` mechanism is implemented to handle initial connection errors.
 * Since the initial connection error does not trigger attempt error handling,
 * this mechanism counts the initial connection attempts and retries the connection cycle.
 */
@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    private socket!: Socket | null;
    private config = environment.webSocket;
    private connectionAttempts = 0;
    private reconnectionTimeout: any;

    constructor(private logger: LoggerService) {
        this.startSocket();
    }

    private startSocket() {
        const { appName } = environment;
        const { attempt, delay } = this.config.reconnection;
        const query: { token: any; userId?: string; orgId?: string } = {
            token: JSON.parse(localStorage[appName]).token,
        };

        if (environment.stage === 'local') {
            query.userId = JSON.parse(localStorage[appName]).user.id;
            query.orgId = JSON.parse(localStorage[appName]).user.organisationId;
        }

        this.socket = io(`${this.config.url}/notification`, {
            transports: ['websocket'],
            query,
            reconnection: attempt > 0,
            reconnectionAttempts: attempt,
            reconnectionDelay: delay,
        });
        this.setupSocketListeners();
    }

    private setupSocketListeners() {
        if (!this.socket) return;

        this.socket.on('connect', () => {
            this.logger.info('Connected to Websocket');

            this.resetAttempts();
            clearTimeout(this.reconnectionTimeout);
        });

        // Socket.Io doesn't count the initial connection as an attempt.
        this.socket.on('connect_error', () => {
            const { attempt } = this.config.reconnection;
            if (this.connectionAttempts > 0) {
                this.logger.debug(
                    `Connection Error (attempt ${this.connectionAttempts} of ${attempt})`,
                );
            }
            this.connectionAttempts++;

            if (this.connectionAttempts > attempt) {
                this.retryAttempts();
            }
        });

        this.socket.on('disconnect', (reason) => {
            this.logger.warn(`Disconnected from server: ${reason}`);
            this.retryAttempts();
        });

        this.socket.on('error', (error) => {
            this.logger.error('Error:', error);
        });
    }

    private retryAttempts() {
        const { interval } = this.config.reconnection;
        this.logger.debug(
            `Attempting to reconnect in ${interval / MINUTE_IN_MILLISECONDS} minutes...`,
        );

        // Clear previous timeout if any
        clearTimeout(this.reconnectionTimeout);

        this.reconnectionTimeout = setTimeout(() => {
            // Reset attempts for a new reconnection cycle
            this.resetAttempts();

            this.logger.debug('Reconnecting...');
            if (this.socket) {
                this.socket.disconnect(); // Ensure socket is properly disconnected
                this.socket = null;
            }
            this.startSocket();
        }, interval);
    }

    private resetAttempts() {
        this.connectionAttempts = 0;
        this.logger.debug(`Resetting connection attempts to ${this.connectionAttempts}`);
    }

    public getAllMessages(): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            if (!this.socket) return;
            this.socket.onAny((...args) => {
                this.logger.debug(args[0], args[1]);
                observer.next(args);
            });
        });
    }

    public getMessagesByEventType(eventType: WebsocketEventType): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            if (!this.socket) return;
            this.socket.on(eventType, (message: WebsocketMessage) => {
                observer.next(message);
            });
        });
    }

    public getMessagesByEventTypes(eventTypes: WebsocketEventType[]): Observable<any> {
        return new Observable((observer: Observer<any>) => {
            if (!this.socket) return;
            this.socket.onAny((...args) => {
                eventTypes.includes(args[1].externalEventType) ? observer.next(args[1]) : null;
            });
        });
    }

    sendMessage(eventType: WebsocketEventType, message: any) {
        if (!this.socket) return;
        this.socket.emit(eventType, message);
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { faCube } from '@fortawesome/pro-light-svg-icons';
import { environment } from '@app-env';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'fsco-blockchain-ledger',
    templateUrl: './blockchain-ledger.component.html',
    styleUrls: ['./blockchain-ledger.component.scss'],
})
export class BlockchainLedgerComponent implements OnInit {
    @Input() networkType!: string;
    @Input() aggId!: string;
    @Input() transactionId!: string;
    @Input() theme: 'dark' | 'light' = 'light';
    @Input() size: SizeProp = 'sm';
    @Input() type: string = 'default';
    @Input() showText: false | true = false;
    @Input() linkOnly: false | true = false;

    ledgerId!: string;
    ledgerType!: string;
    environment = environment;
    ledgerData: any;
    iconLedger = faCube;

    constructor(/*private eventstoreService: EventStoreService*/) {}

    ngOnInit(): void {
        if (!this.transactionId) {
            //this.loadData();
        } else {
            this.ledgerId = this.transactionId;
            this.ledgerType = this.networkType;
        }
    }

    loadData(): void {
        if (!this.aggId || !this.type) return;

        /*this.eventstoreService.getAggIdEventsBasic(this.aggId, [`event.${this.type}`]).subscribe(
            (data: any) => {
                this.ledgerData = data[0] || [];
                this.ledgerId = this.ledgerData.transactionId;
                this.ledgerType = this.ledgerData.networkType;
            },
            () => {},
        );*/
    }

    networkTxEncode(inputString: string): string {
        const atReplaced = inputString.replace('@', '-');
        const lastDotIndex = atReplaced.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            return atReplaced.substring(0, lastDotIndex) + '-' + atReplaced.substring(lastDotIndex + 1);
        } else {
            return atReplaced;
        }
    }

    goToExplorer(event: MouseEvent): void {
        event.stopPropagation();
        if (!this.ledgerType) return;
        const urlLedger = `${environment.networkUrls[this.ledgerType]}transactions/${this.networkTxEncode(this.ledgerId)}`;
        window.open(urlLedger, '_new');
    }
}

import { Component, Input } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStars } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'fsco-promo-section-panel',
    templateUrl: './promo-section-panel.component.html',
    styleUrls: ['./promo-section-panel.component.scss'],
})
export class PromoSectionPanelComponent {
    @Input() icon: IconProp = faStars;
    @Input() title: string = '';
}

import { Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { DocumentTag } from '@portal-api-models';
import { DocumentGroupTagPipe } from 'src/app/documents/shared/pipes/document-group-tag.pipe';

@Component({
    selector: 'fsco-tag-type',
    templateUrl: './tag-type.component.html',
    styleUrls: ['./tag-type.component.scss'],
    providers: [DocumentGroupTagPipe]
})
export class TagTypeComponent implements OnChanges {
    @Input() label = '';
    @Input() colorName?: DocumentTag.ColorEnum;
    @Input() colorClass?: string;
    @Input() icon?: IconProp;

    effectiveColorClass!: string;

    constructor(@Inject(DocumentGroupTagPipe) private documentGroupTagPipe: DocumentGroupTagPipe) {
        this.updateEffectiveColorClass();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.color || changes.colorHex || changes.colorClass) {
            this.updateEffectiveColorClass();
        }
    }

    private updateEffectiveColorClass(): void {
        if (this.colorName) {
            this.effectiveColorClass = `bg-${this.getColorClass(this.colorName)}`;
        } else {
            this.effectiveColorClass = `bg-${this.colorClass}` ?? '';
        }
    }

    private getColorClass(color: DocumentTag.ColorEnum): string {
        return this.documentGroupTagPipe.transform(color).colorClass;
    }
}

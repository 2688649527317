import { Component, Input } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'fsco-onboarding-card',
    templateUrl: './onboarding-card.component.html',
    styleUrls: ['./onboarding-card.component.scss'],
})
export class OnboardingCardComponent {
    @Input() title!: string;
    @Input() titlePosition: 'inline' | 'block' = 'inline';
    @Input() description!: string;
    @Input() icon!: IconProp;
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ComponentSize } from '@fsco-components/core/types/enum';
import { faFile } from '@fsco/fontawesome-pro/pro-light-svg-icons';

@Component({
    selector: 'fsco-icon-type',
    templateUrl: './icon-type.component.html',
    styleUrls: ['./icon-type.component.scss'],
})
export class IconTypeComponent implements OnInit, OnDestroy {
    private _icon: IconProp = faFile;
    private _color: string = 'gray-200';

    @Input()
    set color(value: string | null) {
        this._color = value ?? 'gray-200';
    }
    get color(): string {
        return this._color;
    }

    @Input()
    set icon(value: IconProp | null) {
        this._icon = value ?? faFile;
    }
    get icon(): IconProp {
        return this._icon;
    }

    @Input() size?: ComponentSize = 'md';

    ngOnInit() {}
    ngOnDestroy() {}
}

/**
 * Assets Portal API
 * This specifies the Asset endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { AssetCollectionCreateUpdateMetaStructure } from './assetCollectionCreateUpdateMetaStructure';

export class AssetCollectionCreateUpdate {
    'name': string;
    'description': string;
    'contractDeploymentId'?: string;
    'metaStructure'?: AssetCollectionCreateUpdateMetaStructure;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "contractDeploymentId",
            "baseName": "contractDeploymentId",
            "type": "string"
        },
        {
            "name": "metaStructure",
            "baseName": "metaStructure",
            "type": "AssetCollectionCreateUpdateMetaStructure"
        }    ];

    static getAttributeTypeMap() {
        return AssetCollectionCreateUpdate.attributeTypeMap;
    }
}


import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NavMenuItem, PositionHorizontal, PositionVertical } from './navigation-menu.model';

@Component({
    selector: 'fsco-navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.scss'],
})
export class NavigationMenuComponent {
    @Input() items: NavMenuItem[] = [];
    @Input() positionVertical: PositionVertical = PositionVertical.Below;
    @Input() positionHorizontal: PositionHorizontal = PositionHorizontal.Left;

    /** @description Space between the menu and the top of the screen */
    @Input() spaceTop?: number = 0;

    /** @description Space between the menu and the bottom of the screen */
    @Input() spaceBottom?: number = 0;

    @Output() onMenuItemSelected = new EventEmitter<NavMenuItem>();

    constructor(private elementRef: ElementRef) {}

    toggleSubMenu(item: NavMenuItem): void {
        item.expanded = !item.expanded;
    }

    itemClick(item: NavMenuItem): void {
        if (item.command) {
            item.command();
            this.onMenuItemSelected.emit(item);
        }
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside) {
            this.onMenuItemSelected.emit(undefined);
        }
    }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'fsco-skeleton-detail-with-tabs',
    templateUrl: './skeleton-detail-with-tabs.component.html',
    styleUrls: ['./skeleton-detail-with-tabs.component.scss'],
})
export class SkeletonDetailWithTabsComponent implements OnChanges {
    @Input() cols: number = 3;
    @Input() rows: number = 1;
    @Input() size: 'sm' | 'md' = 'md';
    rowsItems!: any[];
    colsItems!: any[];
    colClass!: string;
    rowClass!: string;

    constructor() {
        this.buildItems();
        this.updateColClass();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.cols || changes.rows || changes.size) {
            this.buildItems();
            this.updateColClass();
        }
    }

    buildItems() {
        this.rowsItems = Array(this.rows).fill({});
        this.colsItems = Array(this.cols).fill({});
    }

    updateColClass() {
        const colSize = Math.floor(12 / this.cols);
        this.colClass = `col-${colSize}`;

        if (this.size == 'sm') this.rowClass = `h-6rem`;
        else if (this.size == 'md') this.rowClass = `h-18rem`;
    }
}

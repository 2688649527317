<ng-container>
    <div class="grid p-fluid selector-options selector-options-{{ size }}">
        <ng-container *ngIf="!isLoading && items && items.length > 0">
            <div
                class="{{
                    isPillTemplate()
                        ? 'flex flex-wrap p-justify-content-between mr-2 mb-2'
                        : 'col-' + columnWidth + ' lg:col-' + columnWidth + ' md:col-' + columnWidth + ' sm:col-12'
                }}"
                *ngFor="let item of items"
            >
                <button
                    class="selector-options-button flex w-full px-3 py-2 border-solid border-1 align-items-center {{ textAlignClass() }}"
                    [ngClass]="{ active: item.selected, 'theme--pill': isPillTemplate() }"
                    (click)="selectItem(item)"
                >
                    <div
                        class="selector-options-button-label mt-2 mb-2 white-space-nowrap text-overflow-ellipsis {{ textAlignClass() }}"
                        [ngClass]="{
                            'text-sm font-medium': isSmallSize(),
                            'text-base font-semibold': isMediumSize(),
                            'text-lg font-semibold': isLargeSize()
                        }"
                    >
                        <div
                            class="selector-options-button-label--content w-full white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        >
                            {{ item.label }}
                        </div>
                    </div>
                    <div
                        class="selector-options-button-icon selector-options-button-icon--activated border-circle justify-content-center align-items-center align-content-center font-semibold ml-2"
                    >
                        <fa-icon [icon]="iconActivated" size="1x"></fa-icon>
                    </div>
                    <div
                        class="selector-options-button-icon selector-options-button-icon--deactivated flex justify-content-center align-items-center align-content-center font-semibold ml-2"
                        *ngIf="iconDeactivated"
                    >
                        <fa-icon [icon]="iconDeactivated" size="1x"></fa-icon>
                    </div>
                </button>
            </div>
        </ng-container>
        <!-- Skeleton / Starts -->
        <ng-container *ngIf="isLoading">
            <div
                class="{{ isPillTemplate() ? 'flex flex-wrap p-justify-content-between mr-3 mb-3' : 'col-' + columnWidth }}"
                *ngFor="let _ of getSkeletonItems(skeleton.items)"
            >
                <p-skeleton width="100%" height="4rem" *ngIf="!isPillTemplate()"></p-skeleton>
                <p-skeleton width="100%" height="3rem" width="10rem" borderRadius="50px" *ngIf="isPillTemplate()"></p-skeleton>
            </div>
        </ng-container>
        <!-- Skeleton / Ends -->
    </div>
</ng-container>

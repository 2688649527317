<div class="flex justify-content-center surface-ground m-4 p-6 pb-8 border-round min-h-screen">
    <div>
        <div class="mx-2">
            <h2>Hello, {{userName}}!</h2>
            <!-- add brake points for size -->
            <h4 class="w-9 font-medium">
                Get to know our <a [routerLink]="routePath.DOCS_LIST">AI and OCR tool</a> that streamlines document data extraction, and tokenisation on the
                blockchain. Experience efficient, accurate document management—a smarter way of working awaits you.
            </h4>
        </div>
        <div class="flex surface-0 border-round p-4 mx-2 mt-5 shadow-1">
            <div class="text-color-green-1 surface-fsco-2 border-round p-3">
                <fa-icon [icon]="icon" size="3x"></fa-icon>
            </div>
            <div class="flex flex-column justify-content-center pl-4 w-full">
                <h2 class="m-0 p-0 font-semibold text-lg mb-2">Ready to start your first upload?</h2>
                <div class="font-regular text-base">Upload your first document with our AI-powered data extraction tool.</div>
            </div>
            <div class="flex align-content-start">
                <p-button (click)="handleNavigation(routePath.DOCS_LIST)" class="white-space-nowrap" label="New upload" size="small"></p-button>
            </div>
        </div>
        <fsco-card-image (navigateRequest)="handleNavigation($event)"></fsco-card-image>
    </div>
</div>

/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentGroupPriority } from './documentGroupPriority';
import type { DocumentGroupStatus } from './documentGroupStatus';
import type { DocumentGroupType } from './documentGroupType';
import type { DocumentTag } from './documentTag';

export class DocumentGroup {
    'id': string;
    'name': string;
    'description'?: string;
    'typeId'?: string;
    'organisationId'?: string;
    'status'?: DocumentGroupStatus;
    'type'?: DocumentGroupType;
    'documents'?: Array<string>;
    'tags'?: Array<DocumentTag>;
    'priority'?: DocumentGroupPriority;
    'createdAt'?: string;
    'updatedAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "typeId",
            "baseName": "typeId",
            "type": "string"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "DocumentGroupStatus"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "DocumentGroupType"
        },
        {
            "name": "documents",
            "baseName": "documents",
            "type": "Array<string>"
        },
        {
            "name": "tags",
            "baseName": "tags",
            "type": "Array<DocumentTag>"
        },
        {
            "name": "priority",
            "baseName": "priority",
            "type": "DocumentGroupPriority"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return DocumentGroup.attributeTypeMap;
    }
}

export namespace DocumentGroup {
}

<div class="promo-section-panel w-full block bg-primary-50 surface-b border-round mb-4 mt-4 p-5">
    <div class="promo-section-panel-wrapper flex align-items-start align-items-center p-0">
        <div class="promo-section-panel-icon text-color-primary-800 border-round p-3">
            <fa-icon [icon]="icon" size="3x"></fa-icon>
        </div>
        <div class="pl-4">
            <h2 class="m-0 p-0 font-semibold text-base mb-2" *ngIf="title">{{ title }}</h2>
            <div class="font-regular text-base">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { environment } from '@app-env';

export enum LogLevel {
    Debug = 'debug',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
}

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    private context: string = '';
    private logLevel: LogLevel = environment.logLevel;

    private shouldLog(level: LogLevel): boolean {
        const order = [LogLevel.Debug, LogLevel.Info, LogLevel.Warn, LogLevel.Error];
        return order.indexOf(level) >= order.indexOf(this.logLevel);
    }

    private logColors = {
        [LogLevel.Debug]: 'color: yellow;',
        [LogLevel.Info]: 'color: cyan;',
        [LogLevel.Warn]: 'color: orange;',
        [LogLevel.Error]: 'color: red;',
    };

    private logEmojis = {
        [LogLevel.Debug]: '🐛',
        [LogLevel.Info]: 'ℹ️',
        [LogLevel.Warn]: '⚠️',
        [LogLevel.Error]: '🚨',
    };

    setContext(context: string) {
        this.context = context;
    }

    log(level: LogLevel, message: any, ...optionalParams: any[]) {
        if (!this.shouldLog(level)) return;

        const emoji = this.logEmojis[level];
        const colorStyle = this.logColors[level];
        let contextMessage;

        if (this.context) {
            contextMessage = `[${emoji} ${this.context}] ${message}`;
        } else {
            contextMessage = `${emoji} ${message}`;
        }

        const formattedMessage = `%c${contextMessage}%c`;
        const resetStyle = 'color: initial;';

        switch (level) {
            case LogLevel.Debug:
                console.log(formattedMessage, colorStyle, resetStyle, ...optionalParams);
                break;
            case LogLevel.Info:
                console.info(formattedMessage, colorStyle, resetStyle, ...optionalParams);
                break;
            case LogLevel.Warn:
                console.warn(formattedMessage, colorStyle, resetStyle, ...optionalParams);
                break;
            case LogLevel.Error:
                console.error(formattedMessage, colorStyle, resetStyle, ...optionalParams);
                break;
        }
    }

    debug(message: any, ...optionalParams: any[]) {
        this.log(LogLevel.Debug, message, ...optionalParams);
    }

    info(message: any, ...optionalParams: any[]) {
        this.log(LogLevel.Info, message, ...optionalParams);
    }

    warn(message: any, ...optionalParams: any[]) {
        this.log(LogLevel.Warn, message, ...optionalParams);
    }

    error(message: any, ...optionalParams: any[]) {
        this.log(LogLevel.Error, message, ...optionalParams);
    }
}

<ng-container *ngIf="ledgerId">
    <button
        *ngIf="!linkOnly"
        title="{{ aggId }}"
        (click)="goToExplorer($event)"
        class="BlockChainButton text-center cursor-pointer text-green-700 text-lg border-none p-1 white-space-nowrap"
        [ngClass]="{ 'border-round-lg border-1 border-green-700 px-3 py-2': showText }"
    >
        <fa-icon [icon]="iconLedger"></fa-icon>
        <span *ngIf="showText" class="ml-1 text-sm white-space-nowrap">Blockchain</span>
    </button>
    <span *ngIf="linkOnly" class="text-green-700 cursor-pointer white-space-nowrap" (click)="goToExplorer($event)">
        <fa-icon [icon]="iconLedger" [size]="size" class="mr-1"></fa-icon>
        <span *ngIf="showText">Blockchain</span>
    </span>
</ng-container>

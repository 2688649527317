/**
 * Document Prompt Tester Portal API
 * This document specifies the document prompt tester endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class DocumentsProviderModel {
    'providerModelId'?: string;
    'providerName'?: string;
    'modelName'?: string;
    'description'?: string;
    'displayName'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "providerModelId",
            "baseName": "providerModelId",
            "type": "string"
        },
        {
            "name": "providerName",
            "baseName": "providerName",
            "type": "string"
        },
        {
            "name": "modelName",
            "baseName": "modelName",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "displayName",
            "baseName": "displayName",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return DocumentsProviderModel.attributeTypeMap;
    }
}


<div class="w-full p-d-flex p-flex-column p-ai-center p-mt-4 font-regular px-5">
    <!-- Heading with Icon and Title -->
    <div class="icon-title-container pt-6 pb-0 icon-title-{{ titlePosition }}">
        <fa-icon [icon]="icon" class="p-mr-2 text-2xl mb-1"></fa-icon>
        <h1 class="p-d-flex p-ai-center mt-2 mb-0 p-0 font-semibold">
            {{ title }}
        </h1>
    </div>

    <!-- Description -->
    <div class="p-mt-2 py-3">
        {{ description }}
    </div>

    <!-- Content Projection -->
    <div>
        <ng-content></ng-content>
    </div>
</div>

<div class="plans-selection-skeleton p-5">
    <div class="plan-cards flex justify-around flex-wrap mb-5">
        <div class="plan-card w-1/3 m-2 flex-1 flex-shrink-0 min-h-[300px]" *ngFor="let i of [].constructor(3)">
            <div class="skeleton-card transition-shadow hover:shadow-lg">
                <div class="mb-2">
                    <p-skeleton width="50%" height="4rem"></p-skeleton>
                </div>
                <p-skeleton width="40%" height="3rem"></p-skeleton>
                <ul class="list-none p-0 my-2 mt-2">
                    <li class="skeleton-entitlement mb-1 mt-2" *ngFor="let j of [].constructor(3)">
                        <p-skeleton width="50%" height="2rem" class="skeleton-entitlement-name"></p-skeleton>
                        <ul class="list-none p-0 my-1">
                            <li class="skeleton-metric mb-1 mt-15" *ngFor="let k of [].constructor(1)">
                                <p-skeleton width="80%" height="1.5rem" class="skeleton-metric-name"></p-skeleton>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p-skeleton width="30%" height="3rem"></p-skeleton>
            </div>
        </div>
    </div>
</div>
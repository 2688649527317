import { EventTypeInfo } from '@shared/types/notifications';

/**
 * @description Use this to subscribe websocket events
 */
export enum EventTypeDocument {
    DOCUMENT_CREATED = 'document.created',
    DOCUMENT_SCANNED = 'document.scanned',
    DOCUMENT_UPDATED = 'document.updated',
    OCR_STARTED = 'ocr.started',
    OCR_COMPLETED = 'ocr.completed',
    AI_STARTED = 'ai.started',
    AI_PROCESSED = 'ai.processed',
    AI_DONE = 'ai.event.contentPrompted',
    AI_TESTED = 'ai.promptTested',
}


/**
 * @description Use this to display extended data of every event
 */
export const EventTypeDocumentInfo: Record<EventTypeDocument, EventTypeInfo> = {
    [EventTypeDocument.DOCUMENT_CREATED]: {
        title: 'Document Created',
        description: 'A new document has been created',
    },
    [EventTypeDocument.DOCUMENT_SCANNED]: {
        title: 'Document Scanned',
        description: 'The document has been scanned',
    },
    [EventTypeDocument.DOCUMENT_UPDATED]: {
        title: 'Document Updated',
        description: 'The document has been updated',
    },
    [EventTypeDocument.OCR_STARTED]: {
        title: 'OCR Started',
        description: 'OCR process has started',
    },
    [EventTypeDocument.OCR_COMPLETED]: {
        title: 'OCR Completed',
        description: 'OCR process has completed',
    },
    [EventTypeDocument.AI_STARTED]: {
        title: 'AI Processing Started',
        description: 'AI processing of the document has started',
    },
    [EventTypeDocument.AI_PROCESSED]: {
        title: 'AI Processing Complete',
        description: 'AI has finished processing the document',
    },
    [EventTypeDocument.AI_DONE]: {
        title: 'AI Content Prompted',
        description: 'AI has generated content based on prompts',
    },
    [EventTypeDocument.AI_TESTED]: {
        title: 'AI Prompt Tested',
        description: 'An AI prompt has been tested',
    },
};

import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'fsco-skeleton-plans-selection',
    templateUrl: './plans-selection-skeleton.component.html',
    styleUrls: ['./plans-selection-skeleton.component.scss']
})
export class PlansSelectionSkeletonComponent implements OnInit {
    ngOnInit(): void {

    }
}

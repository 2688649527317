import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SubmenuDivider, SubmenuSection, SubmenuTitle } from '../sub-menu/sub-menu-model';

export interface MainMenu {
    top?: MenuSection;
    bottom?: MenuSection;
}

export interface MenuSection {
    items?: MenuItem[];
    styles?: {
        default?: MenuItemStyle;
        hover?: MenuItemStyle;
        active?: MenuItemStyle;
    };
}

export interface MenuItemStyle {
    iconColor?: string;
    textColor?: string;
    backgroundColor?: string;
    iconBackgroundColor?: string;
}

/**
 * Represents a menu item for navigation.
 */
export class MenuItem {
    icon?: IconProp;
    label?: string;
    link?: string;
    linkParams?: { [key: string]: string };
    exactMatch: boolean = false;
    subMenu?: (SubmenuTitle | SubmenuSection | SubmenuDivider)[] = [];

    /**
     * Creates a new MenuItem instance.
     *
     * @param options - An object containing properties to initialize the menu item.
     *                  Allows for extensibility and easier parameter management.
     *
     * @property icon - The icon for the menu item.
     * @property label - The text label for the menu item.
     * @property link - The navigation link for the menu item.
     * @property exactMatch - (Optional) Flag to indicate if the link is an exact match.
     *                        Defaults to false if not provided.
     * @property subMenu - An array of submenu items associated with the menu item.
     */
    constructor(options: {
        icon: IconProp;
        label: string;
        link: string[] | string;
        exactMatch?: boolean;
        subMenu?: (SubmenuTitle | SubmenuSection | SubmenuDivider)[];
    }) {
        Object.assign(this, options);
        this.exactMatch = options.exactMatch || this.exactMatch;
    }
}

/**
 * Assets Portal API
 * This specifies the Asset endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { AssetMetadata } from './assetMetadata';
import type { AssetStatus } from './assetStatus';
import type { AssetType } from './assetType';

export class AssetPartial {
    'id'?: string;
    'walletAddress'?: string;
    'type'?: AssetType;
    'metadata'?: AssetMetadata;
    'tokenId'?: string;
    'status'?: AssetStatus;
    'updatedAt'?: string;
    'createdAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "walletAddress",
            "baseName": "walletAddress",
            "type": "string"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "AssetType"
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "AssetMetadata"
        },
        {
            "name": "tokenId",
            "baseName": "tokenId",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "AssetStatus"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return AssetPartial.attributeTypeMap;
    }
}

export namespace AssetPartial {
}

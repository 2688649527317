<p-confirmDialog appendTo="body" #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div
                class="border-circle bg-{{
                    message.acceptButtonStyleClass ?? 'primary'
                }} inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8"
            >
                <i *ngIf="message.icon" class="pi pi-{{ message.icon }} text-5xl text-white bounce"></i>
                <ng-container *ngIf="!message.icon">
                    <fa-icon [icon]="getIconClass(message)" class="text-5xl text-white bounce"></fa-icon>
                </ng-container>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <button
                    *ngIf="message?.acceptVisible !== false"
                    pButton
                    label="{{ message.acceptLabel ?? 'Save' }}"
                    (click)="cd.accept()"
                    class="w-8rem p-button-{{ message.acceptButtonStyleClass ?? 'primary' }}"
                ></button>
                <button
                    pButton
                    label="{{ message.rejectLabel ?? 'Cancel' }}"
                    (click)="cd.reject()"
                    class="p-button-outlined w-8rem"
                ></button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

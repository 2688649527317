/**
 * Wallets Portal API
 * This specifies the Wallet endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { Custodian } from './custodian';
import type { WalletNetwork } from './walletNetwork';
import type { WalletTokenBalance } from './walletTokenBalance';

export class Wallet {
    'name': string;
    'custodian': Custodian;
    'id': string;
    'address': string;
    'tokens'?: Array<WalletTokenBalance>;
    'networks'?: Array<WalletNetwork>;
    'category'?: string;
    'createdAt'?: string;
    'updatedAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "custodian",
            "baseName": "custodian",
            "type": "Custodian"
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "address",
            "baseName": "address",
            "type": "string"
        },
        {
            "name": "tokens",
            "baseName": "tokens",
            "type": "Array<WalletTokenBalance>"
        },
        {
            "name": "networks",
            "baseName": "networks",
            "type": "Array<WalletNetwork>"
        },
        {
            "name": "category",
            "baseName": "category",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return Wallet.attributeTypeMap;
    }
}

export namespace Wallet {
}

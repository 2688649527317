<div [ngClass]="{
        'menu-above': positionVertical === 'above',
        'menu-below': positionVertical === 'below',
        'menu-left': positionHorizontal === 'left',
        'menu-right': positionHorizontal === 'right'
    }" class="context-menu pt-{{ spaceTop }} pb-{{spaceBottom}}">
    <div class="context-menu-content border-round pt-1 pb-2 shadow-4 my-1 nav-hover-bg">
        <div (click)="itemClick(item)" *ngFor="let item of items" class="w-full px-4 pt-3 pb-2 cursor-pointer overflow-visible">
            <fa-icon *ngIf="item.icon" [icon]="item.icon" class="mr-2"></fa-icon>
            <a [routerLink]="item.routerLink || null" class="p-text-nowrap p-text-secondary">
                <span class="text-l font-normal"> {{ item.label }}</span>
            </a>
        </div>
    </div>
</div>

/**
 * Document Templates Portal API
 * This document specifies the document templates endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class DocumentTemplateCreateResponse {
    'templateVersionId'?: string;
    'templateId'?: string;
    'version'?: number;
    'providerModelId'?: string;
    'name'?: string;
    'description'?: string;
    'createdAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "templateVersionId",
            "baseName": "templateVersionId",
            "type": "string"
        },
        {
            "name": "templateId",
            "baseName": "templateId",
            "type": "string"
        },
        {
            "name": "version",
            "baseName": "version",
            "type": "number"
        },
        {
            "name": "providerModelId",
            "baseName": "providerModelId",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return DocumentTemplateCreateResponse.attributeTypeMap;
    }
}


import type { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface DocTypeDisplayInfo {
    name: string;
    value: string;
    icon?: IconProp;
    color: string;
    colorHex: string;
}
export interface DocTypeEnum {
    [key: string]: DocTypeDisplayInfo;
}

export enum InputFieldTypeEnum {
    Text = 'text',
    Number = 'number',
}

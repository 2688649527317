/**
 * Groups Portal API
 * Group endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentGroup } from './documentGroup';

export class DocumentGroupsPaginatedResult {
    'total'?: number;
    'page'?: number;
    'perPage'?: number;
    'hasNext'?: boolean;
    'hasPrev'?: boolean;
    'results'?: Array<DocumentGroup>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "total",
            "baseName": "total",
            "type": "number"
        },
        {
            "name": "page",
            "baseName": "page",
            "type": "number"
        },
        {
            "name": "perPage",
            "baseName": "per_page",
            "type": "number"
        },
        {
            "name": "hasNext",
            "baseName": "has_next",
            "type": "boolean"
        },
        {
            "name": "hasPrev",
            "baseName": "has_prev",
            "type": "boolean"
        },
        {
            "name": "results",
            "baseName": "results",
            "type": "Array<DocumentGroup>"
        }    ];

    static getAttributeTypeMap() {
        return DocumentGroupsPaginatedResult.attributeTypeMap;
    }
}


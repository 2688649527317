<ng-container>
    <div
        class="relative w-full h-full p-3 border-round border-1 border-400 surface-card hover:surface-100 cursor-pointer"
        (click)="createMode && handleMainClick()"
    >
        <div class="w-full h-3rem flex align-items-center mb-4 mr-0">
            <fsco-icon-type [icon]="icon" [color]="iconColor"></fsco-icon-type>
            <div class="ml-auto" *ngIf="submenuItems && submenuItems.length > 0">
                <button
                    pButton
                    pRipple
                    icon="pi pi-ellipsis-v"
                    class="p-button-text p-button-plain p-button-rounded"
                    (click)="openContextMenu($event)"
                ></button>
                <p-menu #menuContext [popup]="true" [model]="submenuItems" appendTo="body"></p-menu>
            </div>
        </div>
        <div class="w-full h-5rem mb-6">
            <div class="text-900 text-lg font-medium mb-2" *ngIf="title">
                {{ title }} <p-tag severity="success" *ngIf="isNew" class="ml-2 text-xs">New</p-tag>
            </div>
            <div class="mt-1 mb-0 text-600 font-medium text-sm" *ngIf="subtitle">{{ subtitle }}</div>
            <div
                class="mt-1 mb-0 text-700 font-medium text-sm max-h-1rem overflow-hidden white-space-nowrap text-overflow-ellipsis"
                *ngIf="body"
            >
                {{ body }}
            </div>
        </div>
        <div class="grid w-full flex justify-content-between px-2 py-1 align-items-center" *ngIf="!createMode">
            <div class="col-7 lg:col-12" *ngIf="createButton">
                <p-button size="small" icon="pi pi-chevron-right" label="Create" (click)="handleMainClick()"></p-button>
            </div>
            <div class="col-5 lg:col-12 p-0 overflow-hidden text-overflow-ellipsis text-left" [ngClass]="{ 'col-7':!createButton }">
                <p-button
                    size="small"
                    label=""
                    (click)="handleSecondaryClick()"
                    class="cursor-pointer bg-transparent white-space-nowrap overflow-hidden text-overflow-ellipsis p-0 text-base font-medium"
                    [outlined]="true"
                >
                    View details
                </p-button>
            </div>
        </div>

        <!-- Updating - Starts -->
        <div
            *ngIf="isUpdating"
            class="absolute flex top-0 left-0 w-full h-full bg-white-alpha-70 border-round align-content-center align-items-center justify-content-center"
        >
            <p-progressSpinner styleClass="progress-spinner w-3rem h-3rem" strokeWidth="6" animationDuration=".5s"></p-progressSpinner>
        </div>
        <!-- Updating - Ends -->
    </div>
</ng-container>

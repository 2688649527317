import { Component, Input, ViewChild } from '@angular/core';
import { Sidebar } from 'primeng/sidebar';

@Component({
    selector: 'fsco-sidebar', //sidebar
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    @ViewChild(Sidebar) sidebar?: Sidebar;

    @Input() sidebarVisible: boolean = false;
    @Input() sidebarPosition: string = 'right';
    @Input() size: string = 'lg';

    public show() {
        this.sidebarVisible = true;
    }

    public hide() {
        this.sidebarVisible = false;
    }

    public close() {
        this.sidebar?.close(new Event('click'));
    }
}

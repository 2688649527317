/**
 * Blob Service API
 * API for uploading files to the blob service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class UploadFileResponseAccess {
    /**
    * URL to get the file
    */
    'get'?: string;
    /**
    * URL to update the file
    */
    'put'?: string;
    /**
    * URL to delete the file
    */
    '_delete'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "get",
            "baseName": "get",
            "type": "string"
        },
        {
            "name": "put",
            "baseName": "put",
            "type": "string"
        },
        {
            "name": "_delete",
            "baseName": "delete",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return UploadFileResponseAccess.attributeTypeMap;
    }
}


import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastPositionType } from 'primeng/toast';

@Component({
    selector: 'fsco-toast-basic',
    templateUrl: './toast-basic.component.html',
    styleUrls: ['./toast-basic.component.scss'],
})
export class ToastBasicComponent {
    @Input() position: ToastPositionType = 'bottom-right';
    @Input() key: string = '';
    @Input() preventDuplicates: boolean = false;
    @Input() life: number = 8000;
    @Input() closeable: boolean = false;
}

import { Organisation, User, Subscription } from '@portal-api-models';

export class UserSession {
    token?: string;
    version?: number;
    user?: Partial<User>;
    organisation?: Partial<Organisation>;
    display?: UserDisplayDetails;
    subscription?: Subscription;
}

export class UserDisplayDetails {
    user: string = '';
    org: string = '';
    role: string = '';
}

export enum PermissionEnum {
    Super = 2,
    Admin = 3,
}

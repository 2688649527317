/**
 * Users Portal API
 * User endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class UserPartial {
    /**
    * The id is not allowed to be updated.
    */
    'id': any | null;
    'firstName': string;
    'lastName': string;
    /**
    * The email is not allowed to be updated.
    */
    'email'?: any | null;
    /**
    * The verified field is not allowed to be updated.
    */
    'verified'?: any | null;
    'isActive'?: boolean;
    /**
    * The superuser field is not allowed to be updated.
    */
    'superuser'?: any | null;
    'avatarUrl'?: string;
    'phone'?: string;
    'permissionId'?: number;
    /**
    * The organisationId is not allowed to be updated.
    */
    'organisationId'?: any | null;
    /**
    * The createdAt field is not allowed to be updated.
    */
    'createdAt'?: any | null;
    /**
    * The updatedAt field is not allowed to be updated.
    */
    'updatedAt'?: any | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "any"
        },
        {
            "name": "firstName",
            "baseName": "firstName",
            "type": "string"
        },
        {
            "name": "lastName",
            "baseName": "lastName",
            "type": "string"
        },
        {
            "name": "email",
            "baseName": "email",
            "type": "any"
        },
        {
            "name": "verified",
            "baseName": "verified",
            "type": "any"
        },
        {
            "name": "isActive",
            "baseName": "isActive",
            "type": "boolean"
        },
        {
            "name": "superuser",
            "baseName": "superuser",
            "type": "any"
        },
        {
            "name": "avatarUrl",
            "baseName": "avatarUrl",
            "type": "string"
        },
        {
            "name": "phone",
            "baseName": "phone",
            "type": "string"
        },
        {
            "name": "permissionId",
            "baseName": "permissionId",
            "type": "number"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "any"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "any"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "any"
        }    ];

    static getAttributeTypeMap() {
        return UserPartial.attributeTypeMap;
    }
}


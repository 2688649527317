<p-sidebar
    styleClass="{{ size }} multiStepSideBar surface-ground"
    [(visible)]="sidebarVisible"
    [position]="sidebarPosition"
    (onHide)="onClose()"
    (onShow)="onOpen()"
>
    <ng-template pTemplate="header">
        <div *ngIf="showSteps" class="sidebar-header px-5 text-sm font-medium">{{ stepCountHeader }}</div>
        <div *ngIf="!showSteps && stepsHeading" class="sidebar-header px-5 text-sm font-medium">{{ stepsHeading }}</div>
        <ng-content select="[sidebar-header]"></ng-content
    ></ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-column content-wrapper w-full min-h-full h-auto overflow-hidden">
            <div class="flex-grow-1">
                <ng-content select="[sidebar-step-content]"></ng-content>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex-shrink-0 px-3 pb-2" *ngIf="shouldShowButtons() && showFooter">
            <div class="step-handler flex justify-content-end flex-wrap p-0 m-0">
                <button
                    class="step-handler-cancel flex px-4 py-3 border-solid border-1 border-round-xl justify-content-center align-items-center align-content-center text-primary font-semibold cursor-pointer"
                    [disabled]="!stepHandler.isPrevEnabled()"
                    [ngClass]="{ disabled: !stepHandler.isPrevEnabled() }"
                    (click)="this.onCancelClick()"
                    *ngIf="stepHandler.getCurrentStep() == 1"
                >
                    <div
                        class="text-lg white-space-nowrap text-overflow-ellipsis"
                        *ngIf="options && options.buttons && options.buttons.label && options.buttons.label.cancel"
                    >
                        {{ options.buttons.label.cancel }}
                    </div>
                </button>

                <button
                    class="step-handler-back flex px-4 py-3 ml-3 border-solid border-1 border-round-xl justify-content-center align-items-center align-content-center text-primary font-semibold cursor-pointer"
                    [disabled]="!stepHandler.isPrevEnabled()"
                    [ngClass]="{ disabled: !stepHandler.isPrevEnabled() }"
                    (click)="stepHandler.onPrevClick()"
                    *ngIf="stepHandler.getCurrentStep() > 1"
                >
                    <div
                        class="text-lg white-space-nowrap text-overflow-ellipsis"
                        *ngIf="options && options.buttons && options.buttons.label && options.buttons.label.previous"
                    >
                        {{ options.buttons.label.previous }}
                    </div>
                </button>

                <button
                    class="step-handler-next flex px-4 py-3 ml-3 border-none border-round-xl justify-content-center align-items-center align-content-center font-semibold cursor-pointer"
                    [disabled]="!stepHandler.isNextEnabled()"
                    [ngClass]="{ 'step-handler-next--disabled': !stepHandler.isNextEnabled() }"
                    (click)="stepHandler.onNextClick()"
                    *ngIf="stepHandler.getCurrentStep() < stepHandler.getMaxSteps()"
                >
                    <div
                        class="text-lg white-space-nowrap text-overflow-ellipsis"
                        *ngIf="options && options.buttons && options.buttons.label && options.buttons.label.next"
                    >
                        {{ options.buttons.label.next }}
                    </div>
                    <div
                        class="selector-options-button-icon flex ml-2"
                        *ngIf="options && options.buttons && options.buttons.icons && options.buttons.icons.next"
                    >
                        <fa-icon [icon]="options.buttons.icons.next" size="1x"></fa-icon>
                    </div>
                </button>

                <button
                    class="step-handler-save flex px-4 py-3 ml-3 border-none border-round-xl justify-content-center align-items-center align-content-center font-semibold cursor-pointer"
                    [disabled]="!stepHandler.isNextEnabled()"
                    [ngClass]="{ 'step-handler-next--disabled': !stepHandler.isNextEnabled() }"
                    (click)="stepHandler.onNextClick()"
                    *ngIf="stepHandler.getCurrentStep() == stepHandler.getMaxSteps()"
                >
                    <div
                        class="text-lg white-space-nowrap text-overflow-ellipsis"
                        *ngIf="options && options.buttons && options.buttons.label && options.buttons.label.save"
                    >
                        {{ options.buttons.label.save }}
                    </div>
                    <!-- <div
                        class="selector-options-button-icon flex ml-2"
                        *ngIf="options && options.buttons && options.buttons.icons && options.buttons.icons.save"
                    >
                        <fa-icon [icon]="options.buttons.icons.save" size="1x"></fa-icon>
                    </div> -->
                </button>
            </div>
        </div>
    </ng-template>
</p-sidebar>

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild, ChangeDetectorRef, NgZone, OnInit } from '@angular/core';
import {
    MultiStepSidebarOptions,
    multiStepSidebarDefaultOptions,
} from '@fsco-components/page-structure/multi-step-sidebar/multi-step-sidebar.model';
import { SidebarComponent } from '@fsco-components/page-structure/sidebar/sidebar.component';
import { StepHandlerService } from '@fsco-components/page-structure/step-handler/step-handler.service';
import { IStepHandler } from '../step-handler/step-handler.model';
import { IStep } from '../step-handler/step.model';

@Component({
    selector: 'fsco-multi-step-sidebar',
    templateUrl: './multi-step-sidebar.component.html',
    styleUrls: ['./multi-step-sidebar.component.scss'],
})
export class MultiStepSidebarComponent extends SidebarComponent implements IStepHandler, OnInit, OnDestroy {
    private _showFooter: boolean = true;
    options: MultiStepSidebarOptions = { ...multiStepSidebarDefaultOptions };
    stepCountHeader!: string;
    listenerSetUp: boolean = false;

    @Input() showSteps: boolean = true;
    @Input() stepsHeading: string = '';
    @Input() hasResultsPage: boolean = false;

    @Input()
    set showFooter(value: boolean) {
        this._showFooter = value;
    }
    get showFooter(): boolean {
        return this._showFooter;
    }

    @Output() sidebarOpened = new EventEmitter<void>();
    @Output() sidebarClosed = new EventEmitter<void>();

    constructor(
        public stepHandler: StepHandlerService,
        private cdr: ChangeDetectorRef,
        private ngZone: NgZone
    ) {
        super();
    }

    ngOnInit(): void {
        this.ngZone.run(() => {
            this.updateStepCounter();
        });
    }

    ngOnDestroy(): void {
        // Implement any cleanup logic if needed
    }

    updateStepCounter(): void {
        this.stepCountHeader = `Step ${this.stepHandler.getCurrentStep()} of ${this.stepHandler.getMaxSteps()}`;
        this.cdr.detectChanges();
    }

    configure(maxSteps: number, customOptions: Partial<MultiStepSidebarOptions> = {}): void {
        if (customOptions && Object.keys(customOptions).length > 0) {
            const mergedOptions = this.configMerge({ ...multiStepSidebarDefaultOptions }, customOptions);
            this.options = mergedOptions;
        } else {
            const defaultOptions = { ...multiStepSidebarDefaultOptions };
            this.options = defaultOptions;
        }
        this.stepHandler.configure(maxSteps);
        this.updateStepCounter();
    }

    setStep(step: IStep<any, any>): void {
        this.stepHandler.setStep(step);
    }

    setCurrentStep(step: number): void {
        this.stepHandler.setCurrentStep(step);
        this.ngZone.run(() => {
            this.updateStepCounter();
        });
    }

    onStepChanged(
        listener: ({
            currentStep,
            previousStep,
            isFinalAction,
        }: {
            currentStep: number;
            previousStep: number;
            isFinalAction: boolean;
        }) => void,
    ): void {
        this.stepHandler.onStepChanged(listener);
        this.listenerSetUp = true;
    }

    shouldShowButtons(): boolean {
        return this.hasResultsPage ? this.stepHandler.getCurrentStep() < this.stepHandler.getMaxSteps() : true;
    }

    onOpen(): void {
        this.sidebarOpened.emit();
    }

    onClose(): void {
        this.sidebarClosed.emit();
    }

    getStep(): IStep<any, any> {
        return this.stepHandler.getStep();
    }

    getCurrentStep(): number {
        return this.stepHandler.getCurrentStep();
    }

    getMaxSteps(): number {
        return this.stepHandler.getMaxSteps();
    }

    private configMerge(target: any, source: any): any {
        if (source instanceof Object && !(source instanceof Array)) {
            for (const key of Object.keys(source)) {
                if (source[key] instanceof Object && key in target) {
                    target[key] = this.configMerge(target[key], source[key]);
                } else {
                    target[key] = source[key];
                }
            }
        }
        return target;
    }

    onCancelClick() {
        if (this.stepHandler.onPrevClick()) {
            this.close();
        }
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAdd, faFileUpload } from '@fortawesome/pro-light-svg-icons';
import { EmptyStateActionTemplate } from './empty-state-action.model';

@Component({
    selector: 'fsco-empty-state-action',
    templateUrl: './empty-state-action.component.html',
    styleUrls: ['./empty-state-action.component.scss'],
})
export class EmptyStateActionComponent implements OnInit {
    @Input() template: EmptyStateActionTemplate = EmptyStateActionTemplate.Create;
    @Input() icon: IconProp = faAdd;
    @Input() title: string = '';
    @Input() description: string = '';
    @Input() callToAction: string = 'Create';
    @Output() onActionClick = new EventEmitter<void>();

    ngOnInit(): void {
        if (this.template === 'upload') {
            this.title = `Let's get started`;
            this.description = `Upload a document and extract key information in 3 easy steps.`;
            this.icon = faFileUpload;
        }
    }

    handleClick() {
        this.onActionClick.emit();
    }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class ApiService {
    constructor(private http: HttpClient, @Inject(String) private baseUrl: string) {}

    /**
     * Makes a POST request to the specified endpoint with the provided data.
     *
     * @param {string} endpoint The endpoint to call (will be appended to the base URL).
     * @param {any} data The data to send in the POST request.
     * @returns {Observable<any>} An observable with the response from the server.
     */
    post(endpoint: string, data: any): Observable<any> {
        const url = `${this.baseUrl}/${endpoint}`;
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // Add any other headers if necessary
            }),
        };
        return this.http.post(url, data, httpOptions);
    }

    // Add other API methods as necessary, e.g., GET, PUT, DELETE, etc.
}

import { Injectable } from '@angular/core';
import { environment } from '@app-env';

@Injectable({
    providedIn: 'root',
})
/**
 * Service for interacting with the browser's local storage.
 * Provides methods to store and retrieve data from local storage.
 */
export class LocalStorageService {
    private readonly IS_MENU_EXPANDED = 'isMenuExpanded';

    setMenuExpandedState(isExpanded: boolean): void {
        localStorage.setItem(this.IS_MENU_EXPANDED, isExpanded.toString());
    }

    getMenuExpandedState(): boolean {
        const storedState = localStorage.getItem(this.IS_MENU_EXPANDED);
        return storedState ? storedState === 'true' : true;
    }
}

/**
 * Payments Portal API
 * This specifies the Payment endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { PaymethodContext } from './paymethodContext';
import type { PaymethodCreateDetails } from './paymethodCreateDetails';
import type { PaymethodStatus } from './paymethodStatus';
import type { ProviderPaymethod } from './providerPaymethod';

export class Paymethod {
    'id'?: string;
    'userId': string;
    'organisationId': string;
    'providerPaymethod'?: ProviderPaymethod;
    'status': PaymethodStatus;
    'context': PaymethodContext;
    '_default': boolean;
    'name'?: string;
    'details'?: PaymethodCreateDetails;
    'createdAt': string;
    'updatedAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "string"
        },
        {
            "name": "providerPaymethod",
            "baseName": "providerPaymethod",
            "type": "ProviderPaymethod"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "PaymethodStatus"
        },
        {
            "name": "context",
            "baseName": "context",
            "type": "PaymethodContext"
        },
        {
            "name": "_default",
            "baseName": "default",
            "type": "boolean"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "details",
            "baseName": "details",
            "type": "PaymethodCreateDetails"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return Paymethod.attributeTypeMap;
    }
}

export namespace Paymethod {
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AUTH_TOKEN_VERSION } from '@shared/auth/classes/user';
import { SessionService } from '@shared/session.service';
import { Observable } from 'rxjs';

@Injectable()
export class PlanGuard implements CanActivate {
    constructor(private router: Router, private session: SessionService) { }
    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const session = this.session.get();

        if (
            !session.subscription
        ) {
            this.router.navigate(['plans']);
            return false;
        }

        return true;
    }
}

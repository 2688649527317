/**
 * Document Prompt Tester Portal API
 * This document specifies the document prompt tester endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentDataLlmValue } from './documentDataLlmValue';
import type { DocumentPrompt } from './documentPrompt';

/**
* The result of the test request send trough websocket
*/
export class DocumentPromptTestData {
    'id': string;
    'prompts'?: Array<DocumentPrompt>;
    'data'?: { [key: string]: DocumentDataLlmValue; };
    'createdAt'?: Date;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "prompts",
            "baseName": "prompts",
            "type": "Array<DocumentPrompt>"
        },
        {
            "name": "data",
            "baseName": "data",
            "type": "{ [key: string]: DocumentDataLlmValue; }"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date"
        }    ];

    static getAttributeTypeMap() {
        return DocumentPromptTestData.attributeTypeMap;
    }
}


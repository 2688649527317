<ng-container>
    <div class="flex w-full p-4 border-round border-1 border-400 surface-card hover:surface-100 cursor-pointer" (click)="handleMainClick()">
        <div class="h-3rem flex align-items-center mr-3">
            <fsco-icon-type [icon]="icon" [color]="iconColor" [size]="iconSize"></fsco-icon-type>
        </div>
        <div>
            <div class="text-900 text-lg font-medium mb-1" *ngIf="title">
                {{ title }} <p-tag severity="success" *ngIf="isNew" class="ml-2 text-xs">New</p-tag>
            </div>
            <div class="mt-1 mb-0 font-medium text-sm" *ngIf="subtitle">{{ subtitle }}</div>
        </div>
    </div>
</ng-container>

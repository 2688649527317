import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import type { IconProp } from '@fsco/fontawesome-pro/fontawesome-svg-core';
import { faFileArrowUp } from '@fsco/fontawesome-pro/pro-regular-svg-icons';
import { ROUTE_PATHS } from '@shared/navigation.service';
import { OrganisationService } from '@shared/organisation.service';
import { SessionService } from '@shared/session.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cp-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    @Input() route: any;

    icon: IconProp = faFileArrowUp;
    routePath: typeof ROUTE_PATHS = ROUTE_PATHS;
    userName: string = '';
    private sessionSubscription: Subscription;

    constructor(private router: Router, protected orgService: OrganisationService, private sessionService: SessionService) {
        this.sessionSubscription = this.sessionService.getSessionChanges().subscribe({
            next: (userSession) => {
                if (userSession) {
                    this.userName = userSession.user?.firstName || '';
                } else {
                    this.userName = '';
                }
            },
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.sessionSubscription.unsubscribe();
    }

    handleNavigation(route: string | string[]) {
        this.router.navigate(Array.isArray(route) ? route : [route]);
    }
}

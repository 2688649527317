import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { ComponentSize, ComponentTextAlign } from '@fsco-components/core/types/enum';
import { SelectorButtonItem, SelectorButtonTemplate } from './selector-button.model';

@Component({
    selector: 'fsco-selector-button',
    templateUrl: './selector-button.component.html',
    styleUrls: ['./selector-button.component.scss'],
})
export class SelectorButtonComponent implements OnInit, OnDestroy {
    @Input() isLoading: boolean = false;
    @Input() template?: SelectorButtonTemplate = SelectorButtonTemplate.Button;
    @Input() items: SelectorButtonItem[] = [];
    @Input() iconActivated: IconProp = faCheck;
    @Input() iconDeactivated?: IconProp;
    @Input() skeleton: { items: number } = { items: 6 };
    @Input() columns: number = 3;
    @Input() multiple: boolean = false;
    @Input() size?: ComponentSize = 'md';
    @Input() textAlign: ComponentTextAlign = ComponentTextAlign.None;
    @Output() onChange: EventEmitter<SelectorButtonItem | SelectorButtonItem[]> = new EventEmitter<
        SelectorButtonItem | SelectorButtonItem[]
    >();

    ngOnInit() {
        if (this.template === SelectorButtonTemplate.Pill) {
            this.iconDeactivated = faPlus;
        }
    }

    ngOnDestroy() {}

    get columnWidth(): number {
        return this.columns && this.columns >= 1 && this.columns <= 12 ? 12 / this.columns : 4;
    }

    selectItem(item: SelectorButtonItem) {
        if (!this.items || Object.entries(this.items).length === 0) return;
        if (!this.multiple) {
            this.items.forEach((it) => (it.selected = false));
            item.selected = !item.selected;
        } else {
            item.selected = !item.selected;
        }
        const selectedItems = this.items.filter((it) => it.selected);
        this.onChange.emit(this.multiple ? selectedItems : selectedItems[0] || null);
    }

    isPillTemplate(): boolean {
        return this.template === SelectorButtonTemplate.Pill;
    }

    isSmallSize(): boolean {
        return this.size === 'sm';
    }

    isMediumSize(): boolean {
        return this.size === 'md';
    }

    isLargeSize(): boolean {
        return this.size === 'lg';
    }

    textAlignClass(): string {
        if (this.textAlign === ComponentTextAlign.Left) return `text-left justify-content-start`;
        else if (this.textAlign === ComponentTextAlign.Center) return `text-center justify-content-center`;
        else if (this.textAlign === ComponentTextAlign.Right) return `text-right justify-content-end`;
        else return `justify-content-between`;
    }

    getSkeletonItems(length: number): any[] {
        return new Array(length).fill(null);
    }
}

/**
 * Represents the types of events related to the marketplace.
 */
export enum EventTypeMarketPlace {
    /**
     * Asset purchase process has commenced and the earmark is ready for payment
     */
    LISTING_CREATED = 'listing.created',

    /**
     * Funds have been payed against the earmark
     */
    LISTING_PAYMENT_DEPOSITED = 'listing.progressed.purchase',

    /**
     * Notify the seller that the asset has been sold
     */
    LISTING_SOLD = 'listing.sold',

    /**
     * Notify to buyer asset has been bought
     */
    LISTING_BOUGHT = 'listing.bought',
}

<div class="connection-status" *ngIf="isOffline || isSlowConnection" [ngClass]="{
    'offline': isOffline,
    'slow-connection': !isOffline && isSlowConnection
}">
    <ng-container *ngIf="isOffline">
        <fa-icon [icon]="faWifiSlash"></fa-icon>
        <span>
            <strong class="font-bold">You are offline</strong>
            Please check your internet connection.
        </span>
    </ng-container>
    <ng-container *ngIf="!isOffline && isSlowConnection">
        <fa-icon [icon]="faTachometerAlt"></fa-icon>
        <span><strong>Slow connection,</strong> performance may be affected</span>
    </ng-container>
</div>

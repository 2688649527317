import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private showTopBar = new BehaviorSubject<boolean>(false);
    showTopBar$ = this.showTopBar.asObservable();

    updateShowTopBarStatus(show: boolean) {
        this.showTopBar.next(show);
    }
}

import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { LoggerService } from '@shared/log.service';
import { BreadcrumbsService } from './breadcrumbs.service';

@Component({
    selector: 'fsco-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
    public breadcrumbs$ = this.breadcrumbsService.breadcrumbs$;
    public showCopyIcon: boolean = false;
    faCopy = faCopy;
    constructor(private logger: LoggerService, public activatedRoute: ActivatedRoute, private breadcrumbsService: BreadcrumbsService) {
        this.logger.setContext(BreadcrumbsComponent.name);
    }

    copyToClipboard(value: string): void {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                this.logger.debug('Text copied to clipboard');
            })
            .catch((err) => {
                this.logger.debug('Failed to copy text: ', err);
            });
    }

    @HostListener('document:keydown.control.i', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        let idCrumb = null;
        this.breadcrumbs$.subscribe((data) => {
            if (data.length > 0) {
                const lastCrumb = data[data.length - 1];
                if (lastCrumb.isId) {
                    idCrumb = lastCrumb.label;
                }
            }
        });
        if (idCrumb) {
            this.copyToClipboard(idCrumb);
            event.preventDefault();
        }
    }
}

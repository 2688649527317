import { Injectable } from '@angular/core';
import { UserSession, PermissionEnum } from '@shared/session.model';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@app-env';

@Injectable({ providedIn: 'root' })
export class SessionService {
    key: string;
    private sessionSubject: BehaviorSubject<UserSession | null>;

    constructor() {
        this.key = environment.appName;
        const contents = this.get();
        this.sessionSubject = new BehaviorSubject<UserSession | null>(contents);
    }

    get(): UserSession {
        const contents = localStorage.getItem(this.key);
        return contents ? (JSON.parse(contents) as UserSession) : {};
    }

    set(userSession: UserSession) {
        userSession.display = {
            user: userSession.user?.firstName || '',
            org: userSession.organisation?.name || '',
            role: PermissionEnum[userSession.user?.permissionId || 0] || '',
        }
        localStorage.setItem(this.key, JSON.stringify(userSession));
        this.sessionSubject.next(userSession);
    }

    clear() {
        localStorage.removeItem(this.key);
        this.sessionSubject.next(null);
    }

    getSessionChanges() {
        return this.sessionSubject.asObservable();
    }

    isSuperUser(): boolean {
        const token = this.get();
        return token.user?.superuser || false;
    }
    
}

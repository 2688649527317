import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { StyleClassModule } from 'primeng/styleclass';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

export default [
    BadgeModule,
    ButtonModule,
    DividerModule,
    FileUploadModule,
    RadioButtonModule,
    MenuModule,
    SidebarModule,
    SkeletonModule,
    StyleClassModule,
    TabViewModule,
    TooltipModule,
    CardModule,
    TagModule,
    TimelineModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    ToastModule,
    TableModule,
    AvatarModule,
    AvatarGroupModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    ColorPickerModule,
    DropdownModule
];

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@shared/organisation.service';
import { SessionService } from '@shared/session.service';
import { UserService } from '@shared/user.service';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'cp-login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss'],
})
export class LoginComponent {
    private sub: any;

    private code: string = '';
    private state: string = '';

    constructor(
        private authService: AuthService,
        private sessionService: SessionService,
        private orgService: OrganisationService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        if (this.sub !== undefined) {
            this.sub.unsubscribe();
        }
        this.sub = this.route.queryParams.subscribe(async (params: any) => {
            this.code = params.code;
            this.state = params.state;
        });
    }

    async ngOnInit() {
        const authSuccess = await this.authService.exchangeGrantCodeAndSetAuthToken(
            this.code,
            this.state,
        );

        if (authSuccess) {
            forkJoin({
                user: this.userService.getMe().pipe(catchError(() => of(null))),
                organisation: this.orgService.getMe().pipe(catchError(() => of(null))),
            })
                .pipe(
                    switchMap(({ user, organisation }) => {
                        if (user && organisation) {
                            const userSession = this.sessionService.get();
                            userSession.user = user;
                            userSession.organisation = organisation;
                            this.sessionService.set(userSession);
                            this.router.navigate(['/']);
                        } else {
                            this.authService.redirectToLogin();
                        }
                        return of(null);
                    }),
                )
                .subscribe();
        } else if (this.code && this.state) {
            // We seemed to successfully login, but something went wrong
            // at the last hurdle. Logout at the auth server so that we
            // don't get in an endless loop.
            await this.authService.redirectToLogout();
        } else {
            await this.authService.redirectToLogin();
        }
    }

    appChecksHasUserData() {
        return this.authService.hasUserExtendedData();
    }
}

/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class DocumentFileData {
    /**
    * This field can be of any type.
    */
    'anyField'?: any | null;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "anyField",
            "baseName": "anyField",
            "type": "any"
        }    ];

    static getAttributeTypeMap() {
        return DocumentFileData.attributeTypeMap;
    }
}


import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@shared/auth/login/login';
import { LogoutComponent } from '@shared/auth/logout/logout';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './shared/auth/guards/auth.guard';
import { PlanGuard } from '@shared/auth/guards/plan.guard';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then((m) => m.DocumentsModule),
        data: { breadcrumb: 'page.menu.documents' },
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        data: { breadcrumb: 'page.menu.settings' },
        canActivate: [AuthGuard],
    },
    {
        path: 'playground',
        loadChildren: () =>
            import('./playground/playground.module').then((m) => m.PlaygroundModule),
        data: { breadcrumb: 'page.menu.playground' },
        canActivate: [AuthGuard],
    },
    {
        path: 'dev-tools',
        loadChildren: () => import('./dev-tools/devtools.module').then((m) => m.DevtoolModule),
        data: { breadcrumb: 'page.menu.me' },
        canActivate: [AuthGuard],
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('./notifications/notifications.module').then((m) => m.NotificationsModule),
        data: { breadcrumb: 'page.menu.playground' },
        canActivate: [AuthGuard],
    },
    {
        path: 'wallets',
        loadChildren: () => import('./wallets/wallets.module').then((m) => m.WalletsModule),
        data: { breadcrumb: 'page.menu.wallets' },
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'plans',
        loadChildren: () => import('./plans/plans.module').then((m) => m.PlansModule),
        data: { breadcrumb: 'Plans' },
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import type { IconProp } from '@fsco/fontawesome-pro/fontawesome-svg-core';
import {
    faCheckCircle,
    faCommentExclamation,
    faInfoCircle,
    faLightEmergencyOn,
    faQuestion,
    faWarning,
} from '@fsco/fontawesome-pro/pro-light-svg-icons';

@Component({
    selector: 'fsco-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
    icon: string = 'pi-question';

    ngOnInit() {}
    ngOnDestroy() {}

    getIconClass(message: any): IconProp {
        const typeMessage = message?.acceptButtonStyleClass;
        if (typeMessage === 'danger') return faLightEmergencyOn;
        else if (typeMessage === 'warning') return faWarning;
        else if (typeMessage === 'info') return faInfoCircle;
        else if (typeMessage === 'success') return faCheckCircle;
        else if (typeMessage === 'help') return faCommentExclamation;
        return faQuestion;
    }
}

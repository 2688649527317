/**
 * Wallets Portal API
 * This specifies the Wallet endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class WalletTransaction {
    'id'?: string;
    'chainId'?: number;
    'addressSender'?: string;
    'addressReceiver'?: string;
    'status'?: string;
    'amount'?: number;
    'updatedAt'?: string;
    'createdAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "chainId",
            "baseName": "chainId",
            "type": "number"
        },
        {
            "name": "addressSender",
            "baseName": "addressSender",
            "type": "string"
        },
        {
            "name": "addressReceiver",
            "baseName": "addressReceiver",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "string"
        },
        {
            "name": "amount",
            "baseName": "amount",
            "type": "number"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return WalletTransaction.attributeTypeMap;
    }
}


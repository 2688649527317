/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class DocumentTag {
    'id': string;
    'name': string;
    'color': DocumentTag.ColorEnum;
    'description'?: string;
    'nameHash'?: string;
    'status'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "color",
            "baseName": "color",
            "type": "DocumentTag.ColorEnum"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "nameHash",
            "baseName": "nameHash",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return DocumentTag.attributeTypeMap;
    }
}

export namespace DocumentTag {
    export enum ColorEnum {
        Blue = <any> 'Blue',
        Pink = <any> 'Pink',
        Purple = <any> 'Purple',
        Orange = <any> 'Orange'
    }
}

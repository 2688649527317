/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentPrompt } from './documentPrompt';

export class DocumentCreate {
    'refId'?: string;
    'docType': string;
    'docName': string;
    'docExt': string;
    'source'?: string;
    'templateId'?: string;
    'groupId'?: string;
    'prompts'?: Array<DocumentPrompt>;
    'uploadBatchId'?: string;
    'isTest'?: boolean;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "refId",
            "baseName": "refId",
            "type": "string"
        },
        {
            "name": "docType",
            "baseName": "docType",
            "type": "string"
        },
        {
            "name": "docName",
            "baseName": "docName",
            "type": "string"
        },
        {
            "name": "docExt",
            "baseName": "docExt",
            "type": "string"
        },
        {
            "name": "source",
            "baseName": "source",
            "type": "string"
        },
        {
            "name": "templateId",
            "baseName": "templateId",
            "type": "string"
        },
        {
            "name": "groupId",
            "baseName": "groupId",
            "type": "string"
        },
        {
            "name": "prompts",
            "baseName": "prompts",
            "type": "Array<DocumentPrompt>"
        },
        {
            "name": "uploadBatchId",
            "baseName": "uploadBatchId",
            "type": "string"
        },
        {
            "name": "isTest",
            "baseName": "isTest",
            "type": "boolean"
        }    ];

    static getAttributeTypeMap() {
        return DocumentCreate.attributeTypeMap;
    }
}


<div class="flex w-full justify-content-center align-content-center" [ngClass]="[( !isJson ? 'align-items-center':''), setClass()]">
    <ng-container *ngIf="!isRemoteUrl">
        <!-- if is an image -->
        <img *ngIf="isImage" [src]="boxPreview" alt="Image preview" class="w-full" />
        <!-- elseif is a pdf -->
        <embed *ngIf="isPdf" [src]="boxPreview" type="application/pdf" class="preview-pdf w-full border-none" [ngClass]="[setClass()]"/>
    </ng-container>
    <ng-container *ngIf="isRemoteUrl">
        <!-- if is a remote image -->
        <img *ngIf="isImage && isFilePreviewable" [src]="boxPreview" alt="Image preview" class="w-full"/>
        <!-- if is a remote pdf -->
        <iframe *ngIf="isPdf && isFilePreviewable" [src]="boxPreview" class="preview-iframe w-full border-none" [ngClass]="[( !height ? 'min-h-screen':''), setClass()]"></iframe>
        <!-- if is a remote json -->
        <div *ngIf="isJson && isFilePreviewable" class="d-block w-full overflow-hidden p-4 text-xs">
            <ngx-json-viewer [json]="boxPreview" [expanded]="true"></ngx-json-viewer>
        </div>
        <div *ngIf="!isFilePreviewable" class="flex w-full py-6 px-3 justify-content-center align-items-center border-round-lg border-dashed border-500 border-2" [ngClass]="[setClass()]">
            Preview not available for this document.
        </div>
    </ng-container>
    <!-- else -->
    <div
        *ngIf="!isPdf && !isImage && !isJson && (!isRemoteUrl || isFilePreviewable)"
        class="flex w-full py-6 px-3 justify-content-center border-round-lg border-dashed border-500 border-2"
        [ngClass]="[setClass()]"
    >
        <fa-icon class="flex my-6 align-items-center opacity-50" [icon]="iconPreview" size="2x"></fa-icon>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DocumentTag } from '@portal-api-models';

interface TagStyle {
    colorHex: string;
    colorClass: string;
}

@Pipe({
    name: 'documentGroupTag'
})
export class DocumentGroupTagPipe implements PipeTransform {
    transform(color: DocumentTag.ColorEnum): TagStyle {
        switch (color) {
            case DocumentTag.ColorEnum.Blue:
                return {
                    colorHex: '#D0E1FD',
                    colorClass: 'blue-100'
                };
            case DocumentTag.ColorEnum.Pink:
                return {
                    colorHex: '#FBD2D2',
                    colorClass: 'pink-100'
                };
            case DocumentTag.ColorEnum.Purple:
                return {
                    colorHex: '#EAD6FD',
                    colorClass: 'purple-100'
                };
            case DocumentTag.ColorEnum.Orange:
                return {
                    colorHex: '#FEDDC7',
                    colorClass: 'orange-100'
                };
            default:
                return {
                    colorHex: '#D0E1FD',
                    colorClass: 'blue-100'
                };
        }
    }
}

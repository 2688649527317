/**
 * Websocket Events UI
 * This specifies the Websocket Events UI API Schema
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class WebsocketMessage {
    'notificationId': string;
    'externalEventType': string;
    'userId': string;
    /**
    * The content to be sent.
    */
    'content': { [key: string]: any; };

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "notificationId",
            "baseName": "notificationId",
            "type": "string"
        },
        {
            "name": "externalEventType",
            "baseName": "externalEventType",
            "type": "string"
        },
        {
            "name": "userId",
            "baseName": "userId",
            "type": "string"
        },
        {
            "name": "content",
            "baseName": "content",
            "type": "{ [key: string]: any; }"
        }    ];

    static getAttributeTypeMap() {
        return WebsocketMessage.attributeTypeMap;
    }
}


/**
 * Wallets Portal API
 * This specifies the Wallet endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { ListingAcceptedPaymentMethodsInner } from './listingAcceptedPaymentMethodsInner';
import type { ListingReceiverAccountsInner } from './listingReceiverAccountsInner';

export class Listing {
    /**
    * The unique id for the listing
    */
    'listingId'?: string;
    /**
    * The unique id for the marketplace
    */
    'marketplaceId': string;
    'title': string;
    'description': string;
    'assetIds': Array<string>;
    'state'?: Listing.StateEnum;
    'status'?: Listing.StatusEnum;
    /**
    * The unique id for the payment
    */
    'paymentId'?: string;
    /**
    * The unique id for the signing wallet
    */
    'assetSigningWalletId': string;
    'organisationId'?: string;
    'receiverAccounts'?: Array<ListingReceiverAccountsInner>;
    'createdAt'?: Date;
    'updatedAt'?: Date;
    'acceptedPaymentMethods': Array<ListingAcceptedPaymentMethodsInner>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "listingId",
            "baseName": "listingId",
            "type": "string"
        },
        {
            "name": "marketplaceId",
            "baseName": "marketplaceId",
            "type": "string"
        },
        {
            "name": "title",
            "baseName": "title",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "assetIds",
            "baseName": "assetIds",
            "type": "Array<string>"
        },
        {
            "name": "state",
            "baseName": "state",
            "type": "Listing.StateEnum"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "Listing.StatusEnum"
        },
        {
            "name": "paymentId",
            "baseName": "paymentId",
            "type": "string"
        },
        {
            "name": "assetSigningWalletId",
            "baseName": "assetSigningWalletId",
            "type": "string"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "string"
        },
        {
            "name": "receiverAccounts",
            "baseName": "receiverAccounts",
            "type": "Array<ListingReceiverAccountsInner>"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "Date"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "Date"
        },
        {
            "name": "acceptedPaymentMethods",
            "baseName": "acceptedPaymentMethods",
            "type": "Array<ListingAcceptedPaymentMethodsInner>"
        }    ];

    static getAttributeTypeMap() {
        return Listing.attributeTypeMap;
    }
}

export namespace Listing {
    export enum StateEnum {
        Listed = <any> 'listed',
        Delisted = <any> 'delisted',
        Sold = <any> 'sold',
        Locked = <any> 'locked'
    }
    export enum StatusEnum {
        Active = <any> 'active',
        Inactive = <any> 'inactive'
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MenuStateService } from '@shared/services/menu-state.service';
import { SessionService } from '@shared/session.service';
import { Subscription } from 'rxjs';
import { Header } from './header.model';

@Component({
    selector: 'fsco-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() header!: Header;
    faCircle = faCircle;
    @Output() actionClicked = new EventEmitter<string>();
    private sessionSubscription!: Subscription;
    private menuSubscription!: Subscription;

    orgName = '';
    orgLogoUrl = '';
    orgCustomColor = '';
    isMenuExpanded = true;

    constructor(
        public translate: TranslateService,
        private sessionService: SessionService,
        private menuStateService: MenuStateService,
    ) {
        this.sessionSubscription = this.sessionService.getSessionChanges().subscribe({
            next: (orgSession) => {
                if (orgSession) {
                    this.orgName = orgSession.organisation?.name || '';
                    this.orgLogoUrl = orgSession.organisation?.logoUrl || '';
                    this.orgCustomColor = orgSession.organisation?.customColor || '';
                } else {
                    this.orgName = '';
                }
            },
        });
    }

    ngOnInit() {
        this.menuSubscription = this.menuStateService.menuExpanded$.subscribe((isExpanded) => {
            this.isMenuExpanded = isExpanded;
        });
    }

    ngOnDestroy(): void {
        this.sessionSubscription.unsubscribe();
        this.menuSubscription.unsubscribe();
    }

    onActionClicked(action: string): void {
        this.actionClicked.emit(action);
    }

    switchLang(lang: string) {
        this.translate.use(lang);
    }
}

/**
 * Payments Portal API
 * This specifies the Payment endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { PaymethodContext } from './paymethodContext';
import type { PaymethodCreateDetails } from './paymethodCreateDetails';

export class PaymethodCreate {
    'providerPaymethodId': string;
    'context': PaymethodContext;
    'name'?: string;
    'details'?: PaymethodCreateDetails;
    '_default'?: boolean;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "providerPaymethodId",
            "baseName": "providerPaymethodId",
            "type": "string"
        },
        {
            "name": "context",
            "baseName": "context",
            "type": "PaymethodContext"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "details",
            "baseName": "details",
            "type": "PaymethodCreateDetails"
        },
        {
            "name": "_default",
            "baseName": "default",
            "type": "boolean"
        }    ];

    static getAttributeTypeMap() {
        return PaymethodCreate.attributeTypeMap;
    }
}

export namespace PaymethodCreate {
}

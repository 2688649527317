import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    User, UsersPaginatedResult,
} from '@portal-api-models';
import { AuthService } from '@shared/auth/services/auth.service';
import { LoggerService } from '@shared/log.service';
import { PortalApiService } from '@shared/portal-api/portal-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PORTAL_API_BASE_URL } from '../app.module';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService extends PortalApiService<User, UsersPaginatedResult> {
    constructor(
        private logger: LoggerService,
        protected override auth: AuthService,
        protected http: HttpClient,
        @Inject(PORTAL_API_BASE_URL) protected override apiBaseUrl: string,
    ) {
        super(http, auth, apiBaseUrl, 'user', 'users');
        this.logger.setContext(UserService.name);
    }

    public getMe(): Observable<User> {
        return this.handleErrors(
            this.httpClient
            .get<User>(`${this.apiBaseUrl}/${this.plFragment}/me`, { headers: this.auth.authHeaders() })
            .pipe(map((result) => result as User))
        );
    }

    override create<TResponse = User>(resource: Partial<User>): Observable<TResponse> {
        return this.handleErrors(
            this.httpClient
            .post<TResponse>(`${this.apiBaseUrl}/${this.plFragment}`, { ...resource, loginUrl: environment.uiUrl }, { headers: this.auth.authHeaders() })
            .pipe(map((result: TResponse) => result as TResponse))
        );
    }

    resendInvite(userId: string): Observable<User> {
        return this.handleErrors(
            this.httpClient
            .put<User>(`${this.apiBaseUrl}/${this.plFragment}/${userId}/invite`, { loginUrl: environment.uiUrl }, { headers: this.auth.authHeaders() })
            .pipe(map((result: User) => result as User))
        );
    }
}

<div class="w-full">
    <ng-container *ngFor="let row of rowsItems">
        <div class="grid w-full p-grid mb-2">
            <ng-container *ngFor="let item of colsItems">
                <div [class]="colClass + ' p-2 text-center justify-content-center align-content-center'">
                    <p-skeleton styleClass="w-full {{ rowClass }}"></p-skeleton>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@env';
import { faWarning } from '@fsco/fontawesome-pro/pro-solid-svg-icons';
import { LayoutService } from '@shared/services/layout.service';

@Component({
    selector: 'fsco-header-dev-warning',
    templateUrl: './dev-warning.component.html',
    styleUrls: ['./dev-warning.component.scss'],
})
export class HeaderDevWarningComponent implements OnInit, OnDestroy {
    constructor(private layoutService: LayoutService) {}

    isDevEnvironment = environment.stage === 'local' || environment.stage === 'dev';
    warnMessage =
        environment.stage === 'local'
            ? 'Local Development'
            : 'Development Testing';
    icon = faWarning;

    ngOnInit() {
        if (this.isDevEnvironment) {
            setTimeout(() => {
                this.layoutService.updateShowTopBarStatus(true);
            });
        }
    }

    ngOnDestroy() {
        if (this.isDevEnvironment) {
            this.layoutService.updateShowTopBarStatus(false);
        }
    }
}

export enum LogLevel {
    Debug = 'debug',
    Info = 'info',
    Warn = 'warn',
    Error = 'error',
}

export type Environment = {
    stage: string;
    production: boolean;
    logLevel: LogLevel;
    appName: string;
    authenticationUrl: string;
    uiUrl: string;
    apiBaseUrl: string;
    sentryUrl: string;
    clientKey: string;
    clientId: string;
    webSocket: {
        url: string;
        reconnection: {
            attempt: number;
            delay: number; // How long is wait before reconnecting
            interval: number; // How long is wait to start a new attempt cycle
        };
    };
    networkUrls: { [key: string]: string };
    documents: {
        groups: {
            defaultTypeId: string;
        };
    };
    marketplace: {
        identifiers: { [key: string]: string };
        currencies: {
            contracts: {
                [key: string]: { display: string; address: string };
            };
        };
    };
    loadHotjar: boolean;
    featureWallets: boolean;
    // Add any additional properties here if needed
};

/**
 * Groups Portal API
 * Group endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentGroupPriority } from './documentGroupPriority';
import type { DocumentGroupStatus } from './documentGroupStatus';
import type { DocumentGroupType } from './documentGroupType';

export class DocumentGroupCreate {
    'name': string;
    'description'?: string;
    'typeId'?: string;
    'status'?: DocumentGroupStatus;
    'tags'?: Array<string>;
    'priority'?: DocumentGroupPriority;
    'type'?: DocumentGroupType;
    'documents'?: Array<string>;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "typeId",
            "baseName": "typeId",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "DocumentGroupStatus"
        },
        {
            "name": "tags",
            "baseName": "tags",
            "type": "Array<string>"
        },
        {
            "name": "priority",
            "baseName": "priority",
            "type": "DocumentGroupPriority"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "DocumentGroupType"
        },
        {
            "name": "documents",
            "baseName": "documents",
            "type": "Array<string>"
        }    ];

    static getAttributeTypeMap() {
        return DocumentGroupCreate.attributeTypeMap;
    }
}

export namespace DocumentGroupCreate {
}

<ul
    class="list-none py-3 px-5 m-0 surface-card flex align-items-center font-regular overflow-x-auto breadcrumbs text-sm text-color-secondary"
>
    <li class="pr-3">
        <a routerLink="/" class="cursor-pointer text-color-secondary white-space-nowrap">
            {{ 'page.menu.home' | translate }}
        </a>
    </li>
    <ng-container *ngFor="let breadcrumb of breadcrumbs$ | async; let last = last">
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2" *ngIf="!last">
            <a class="cursor-pointer text-color-secondary white-space-nowrap" [routerLink]="breadcrumb.url">
                {{ breadcrumb.label | translate }}
            </a>
        </li>
        <li class="px-2 relative" *ngIf="last">
            <span *ngIf="!breadcrumb.isId" class="text-color-secondary white-space-nowrap">
                {{ breadcrumb.label | translate }}
            </span>
            <ng-container *ngIf="breadcrumb.isId">
                <span
                    class="text-color-secondary white-space-nowrap"
                    (click)="copyToClipboard(breadcrumb.label)"
                    pTooltip="Copy ID ctrl+i"
                    tooltipPosition="top"
                >
                    {{ breadcrumb.label | translate }}
                    <fa-icon [icon]="faCopy"></fa-icon>
                </span>
            </ng-container>
        </li>
    </ng-container>
</ul>

/**
 * Document Prompt Tester Portal API
 * This document specifies the document prompt tester endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentPrompt } from './documentPrompt';

export class DocumentPromptTest {
    'id': string;
    'documentType': string;
    'documentName': string;
    'tester': boolean;
    'prompts': Array<DocumentPrompt>;
    'llmModel'?: string;
    'llmProvider'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "documentType",
            "baseName": "documentType",
            "type": "string"
        },
        {
            "name": "documentName",
            "baseName": "documentName",
            "type": "string"
        },
        {
            "name": "tester",
            "baseName": "tester",
            "type": "boolean"
        },
        {
            "name": "prompts",
            "baseName": "prompts",
            "type": "Array<DocumentPrompt>"
        },
        {
            "name": "llmModel",
            "baseName": "llmModel",
            "type": "string"
        },
        {
            "name": "llmProvider",
            "baseName": "llmProvider",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return DocumentPromptTest.attributeTypeMap;
    }
}


import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * Standard Routes
 */
export const ROUTE_SEGMENTS = Object.freeze({
    DOCUMENTS: '/documents',
    TEMPLATES: 'templates',
    GROUPS: 'groups',
    PROMPT_TESTER: 'prompt-tester',

    ACCOUNT: '/me',
    DEVTOOLS: '/dev-tools',
    WALLETS: '/wallets',
    ASSETS: 'assets',
    SETTINGS: 'settings',
});

export const ROUTE_ACTIONS = Object.freeze({
    ALLDOCS: 'all',
    CREATE: 'create',
    DETAIL: 'detail',
    EDIT: 'edit',
    DASHBOARD: 'dashboard',
});

export const ROUTE_PATHS = Object.freeze({
    DOCS_DASHBOARD: [ROUTE_SEGMENTS.DOCUMENTS],
    DOCS_LIST: [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_ACTIONS.ALLDOCS],
    DOCS_GROUP: [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.GROUPS],
    DOCS_CREATE: [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_ACTIONS.ALLDOCS, ROUTE_ACTIONS.CREATE],
    DOCS_TEMPLATES: [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.TEMPLATES],
    DOCS_TEMPLATES_CREATE: [
        ROUTE_SEGMENTS.DOCUMENTS,
        ROUTE_SEGMENTS.TEMPLATES,
        ROUTE_ACTIONS.CREATE,
    ],
    DOCS_PROMPT_TESTER: [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.PROMPT_TESTER],

    DEVTOOLS_DASHBOARD: [ROUTE_SEGMENTS.DEVTOOLS],
    DEVTOOLS_API_KEYS: [ROUTE_SEGMENTS.DEVTOOLS, 'api-keys'],
    DEVTOOLS_WEBHOOKS: [ROUTE_SEGMENTS.DEVTOOLS, 'webhooks'],

    WALLET_LIST: [ROUTE_SEGMENTS.WALLETS],
    WALLET_SETTINGS: [ROUTE_SEGMENTS.WALLETS, ROUTE_SEGMENTS.SETTINGS],
});

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    goToDocCreation() {
        this.router.navigate([ROUTE_SEGMENTS.DOCUMENTS, ROUTE_ACTIONS.ALLDOCS, ROUTE_ACTIONS.CREATE]);
    }

    goToDocAllCreation() {
        this.router.navigate([
            ROUTE_SEGMENTS.DOCUMENTS,
            ROUTE_ACTIONS.ALLDOCS,
            ROUTE_ACTIONS.CREATE,
        ]);
    }

    goToGroup() {
        this.router.navigate([ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.GROUPS]);
    }

    goToTemplate() {
        this.router.navigate([ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.TEMPLATES]);
    }

    goToPrompTest() {
        this.router.navigate([ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.PROMPT_TESTER]);
    }

    goToDocCreationWithTemplateId(id: string, type: string) {
        return this.router?.navigate(
            [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_ACTIONS.ALLDOCS, ROUTE_ACTIONS.CREATE],
            {
                queryParams: { templateId: id, type: type },
            },
        );
    }

    goToDocDetail(docId: string, returnUrl: boolean = false): string | Promise<boolean> {
        if (returnUrl) return `${ROUTE_SEGMENTS.DOCUMENTS}/${docId}`;
        return this.router.navigate([ROUTE_SEGMENTS.DOCUMENTS, docId]);
    }

    goToDocTemplateDetail(docTemplateId: string, goBack?: boolean): string | Promise<boolean> {
        let queryParams = {};
        if (goBack) queryParams = { goBack };
        return this.router.navigate(
            [ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.TEMPLATES, docTemplateId],
            { queryParams },
        );
    }

    goToDocTemplateCreation(): string | Promise<boolean> {
        return this.router?.navigate([
            ROUTE_SEGMENTS.DOCUMENTS,
            ROUTE_SEGMENTS.TEMPLATES,
            ROUTE_ACTIONS.CREATE,
        ]);
    }

    goToDocTemplateEdit(docTemplateId: string): string | Promise<boolean> {
        return this.router.navigate([
            ROUTE_SEGMENTS.DOCUMENTS,
            ROUTE_SEGMENTS.TEMPLATES,
            docTemplateId,
            ROUTE_ACTIONS.EDIT,
        ]);
    }

    goToDocPromptDetail(docId: string, returnUrl: boolean = false): string | void {
        const url = `${ROUTE_SEGMENTS.DOCUMENTS}/${ROUTE_SEGMENTS.PROMPT_TESTER}/${docId}`;
        if (returnUrl) return url;
        this.router.navigateByUrl(url);
    }

    goToDocGroupDetail(groupId: string, returnUrl: boolean = false): string | Promise<boolean> {
        if (returnUrl) return `${ROUTE_SEGMENTS.DOCUMENTS}/${ROUTE_SEGMENTS.GROUPS}/${groupId}`;
        return this.router.navigate([ROUTE_SEGMENTS.DOCUMENTS, ROUTE_SEGMENTS.GROUPS, groupId]);
    }

    goToWalletDetail(walletId: string): Promise<boolean> {
        return this.router.navigate([ROUTE_SEGMENTS.WALLETS, walletId]);
    }

    goToAssets(walletId: string): Promise<boolean> {
        return this.router.navigate([ROUTE_SEGMENTS.WALLETS, walletId, ROUTE_SEGMENTS.ASSETS]);
    }

    goToAssetCreation(walletId: string, goBack?: boolean): string | Promise<boolean> {
        let queryParams = {};
        if (goBack) queryParams = { goBack };
        return this.router.navigate(
            [ROUTE_SEGMENTS.WALLETS, walletId, ROUTE_SEGMENTS.ASSETS, ROUTE_ACTIONS.CREATE],
            { queryParams },
        );
    }

    goToCurrentUrlWithoutQuery(queryToRemove: string) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [queryToRemove]: null },
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    }
}

/**
 * Blob Service API
 * API for uploading files to the blob service
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { UploadFileResponseAccess } from './uploadFileResponseAccess';

export class UploadFileResponse {
    /**
    * Unique identifier for the uploaded file
    */
    'blobId': string;
    /**
    * Key or path of the uploaded file
    */
    'key': string;
    /**
    * Indicates if the file is publicly accessible
    */
    '_public': boolean;
    'access': UploadFileResponseAccess;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "blobId",
            "baseName": "blobId",
            "type": "string"
        },
        {
            "name": "key",
            "baseName": "key",
            "type": "string"
        },
        {
            "name": "_public",
            "baseName": "public",
            "type": "boolean"
        },
        {
            "name": "access",
            "baseName": "access",
            "type": "UploadFileResponseAccess"
        }    ];

    static getAttributeTypeMap() {
        return UploadFileResponse.attributeTypeMap;
    }
}


import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AUTH_TOKEN_VERSION } from '@shared/auth/classes/user';
import { SessionService } from '@shared/session.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private session: SessionService) {}
    canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const authTokenJson = this.session.get();
        const restrictedToSuperadmin = next.data['superUser'] || false;

        if (
            !authTokenJson.token ||
            !authTokenJson.user ||
            !authTokenJson.organisation ||
            authTokenJson.version != AUTH_TOKEN_VERSION
        ) {
            this.router.navigate(['login']);
            return false;
        }

        if (restrictedToSuperadmin && !this.session.isSuperUser()) {
            this.router.navigate(['access-denied']);
            return false;
        }

        return true;
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from '@shared/local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class MenuStateService {
    private menuExpandedSubject = new BehaviorSubject<boolean>(this.getInitialMenuState());
    menuExpanded$ = this.menuExpandedSubject.asObservable();

    constructor(private localStorageService: LocalStorageService) {}

    private getInitialMenuState(): boolean {
        const storedState = this.localStorageService.getMenuExpandedState();
        return storedState !== null ? storedState : true;
    }

    setMenuExpanded(isExpanded: boolean) {
        this.menuExpandedSubject.next(isExpanded);
        this.localStorageService.setMenuExpandedState(isExpanded);
    }
}

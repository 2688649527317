<div class="grid grid-nogutter box-sizing: border-box p-0 flex-row align-items-stretch">
    <div
        *ngFor="let card of cards"
        class="flex align-items-stretch align-content-between border-round"
        [ngClass]="getClassesFor(card.breakpoints, 'parent')"
    >
        <div
            class="flex align-content-between mt-3 border-round surface-0 p-4 w-full h-full shadow-5"
            [ngClass]="getClassesFor(card.breakpoints, 'child')"
        >
            <div
                class="bg-no-repeat bg-center border-round background-image-card"
                [ngClass]="getClassesFor(card.breakpoints, 'img')"
                [style.background-image]="'url(' + card.img + ')'"
                [style.background-color]="card.color || 'grey'"
            ></div>
            <div [ngClass]="getClassesFor(card.breakpoints, 'content')">
                <div class="h-full">
                    <h4 class="green-heading my-2">{{ card.title }}</h4>
                    <h2 class="m-0">{{ card.subtitle }}</h2>
                    <p>{{ card.body }}</p>
                </div>
                <div>
                    <p-button
                        (click)="handleButtonClick(card.route)"
                        label="{{ card.buttonLabel }}"
                        [outlined]="true"
                        size="small"
                    ></p-button>
                </div>
            </div>
        </div>
    </div>
</div>

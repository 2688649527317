import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'fsco-tag-status',
    templateUrl: './tag-status.component.html',
    styleUrls: ['./tag-status.component.scss'],
})
export class TagStatusComponent implements OnInit, OnDestroy {
    private _icon?: IconProp;
    private _color: string = 'gray-200';
    private _colorHex?: string = '';

    @Input() label: string = '';

    @Input()
    set color(value: string | null) {
        if (value) {
            this._color = value;
        } else {
            if (!this._colorHex) {
                this._color = 'gray-200';
            }
        }
    }
    get color(): string {
        return this._color;
    }

    @Input()
    set colorHex(value: string | null) {
        this._colorHex = value ?? '';
    }
    get colorHex(): string {
        return this._colorHex ?? '';
    }

    @Input()
    set icon(value: IconProp | undefined) {
        this._icon = value ?? undefined;
    }
    get icon(): IconProp | undefined {
        return this._icon;
    }

    ngOnInit() {}
    ngOnDestroy() {}

    getDynamicClass(): string {
        if (this.color && !this.colorHex) {
            return this.color ? `bg-${this.color}` : '';
        }
        return '';
    }

    getDynamicStyle(): any {
        if (this.colorHex) {
            return { 'background-color': `${this.colorHex} !important` };
        }
        return {};
    }
}

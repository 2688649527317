import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    faCalendar,
    faCalendarCircle,
    faClock,
    faCube,
    faTimer,
} from '@fortawesome/pro-light-svg-icons';
import { DocumentTimeline } from '@portal-api-models';
import { TimeLineEventDisplay } from './events-timeline.model';

@Component({
    selector: 'fsco-events-timeline',
    templateUrl: './events-timeline.component.html',
    styleUrls: ['./events-timeline.component.scss'],
})
export class EventsTimelineComponent implements OnInit, OnChanges {
    @Input() loading: boolean = false;
    @Input() timelineData: TimeLineEventDisplay[] = [];
    @Input() showText: false | true = false;
    @Input() sidebarVisible: boolean = false;
    @Input() sidebarPosition: string = 'right';
    @Input() size: string = 'md';

    @Output() onTimeLineOpened = new EventEmitter();

    icon = {
        default: faCalendarCircle,
        init: faTimer,
        date: faCalendar,
        time: faClock,
        ledger: faCube,
    };

    private skeletonTotal = 10;
    public skeletonItems!: any[];

    ngOnInit(): void {
        this.buildSkeleton();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.timelineData) {
            this.mapTimeLineData();
        }
    }

    mapTimeLineData() {
        if (this.timelineData) {
            this.timelineData = this.timelineData.map((event: TimeLineEventDisplay) => {
                if (!event.display) event.display = { isOpen: false };
                return event;
            });
        }
    }

    onOpenTimeLine() {
        this.onTimeLineOpened.emit();
    }

    openTimeline() {
        this.sidebarVisible = true;
    }

    toggleContent(event: Event, timelineItem: DocumentTimeline): void {
        event.stopPropagation();
        const item = this.timelineData.find((data) => data.id === timelineItem.id);
        if (item) {
            item.display == undefined
                ? (item.display = { isOpen: true })
                : (item.display.isOpen = !item.display!.isOpen);
        }
    }

    buildSkeleton() {
        this.skeletonItems = new Array(this.skeletonTotal).fill({});
    }
}

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

export type MultiStepSidebarOptions = Partial<{
    buttons?: {
        label?: {
            cancel?: string;
            previous?: string;
            next?: string;
            save?: string;
        };
        icons?: {
            previous?: IconProp;
            next?: IconProp;
            save?: IconProp;
        };
    };
}>;

export const multiStepSidebarDefaultOptions: MultiStepSidebarOptions = {
    buttons: {
        label: {
            cancel: 'Cancel',
            previous: 'Previous',
            next: 'Next',
            save: 'Save',
        },
        icons: {
            previous: faChevronRight,
            next: faChevronRight,
            save: faChevronRight,
        },
    },
};

<ng-container *ngIf="activeSubMenu && activeSubMenu.length > 0">
    <div
        class="absolute submenu fixed top-0 flex flex-column align-items-start align-self-stretch h-full min-h-screen pb-0 text-base font-regular"
        [ngClass]="{
            'submenu-expanded': isSubmenuExpanded,
            'submenu-collapsed': !isSubmenuExpanded
        }"
    >
        <div class="submenu-wrapper relative w-full top-0 left-0 pt-5 h-full">
            <div class="submenu-wrapper-items flex flex-column">
                <ng-container *ngFor="let item of activeSubMenu; let i = index">
                    <ng-container [ngSwitch]="true">
                        <!-- SubmenuTitle: Section Header with optional icon -->
                        <ng-container *ngIf="isSubmenuTitle(item)">
                            <div
                                *ngIf="item.icon"
                                class="submenu-header-text w-full p-3 pb-0 text-sm font-semibold"
                            >
                                <fa-icon [icon]="item.icon" class="submenu-header-icon"></fa-icon>
                            </div>
                            <div
                                *ngIf="item.header"
                                class="submenu-header-text w-full p-3 text-sm font-semibold"
                            >
                                {{ item.header || 'DEFAULT_KEY' | translate }}
                            </div>
                        </ng-container>
                        <!-- SubmenuTitleAvatar: Section Header with optional avatar icon -->
                        <ng-container *ngIf="isSubmenuAvatarTitle(item)">
                            <div class="w-full p-3 pb-0 text-sm font-semibold flex">
                                <div
                                    *ngIf="item.background"
                                    class="submenu-header-text flex-none w-min"
                                >
                                    <p-avatar
                                        [label]="getFirstLetterUpper(item.header)"
                                        [style]="{
                                            'background-color': item.background,
                                            color: '#FFFFFF'
                                        }"
                                        shape="circle"
                                        styleClass="mr-3"
                                        size="normal"
                                    ></p-avatar>
                                </div>
                                <div
                                    *ngIf="item.header"
                                    class="submenu-avatar-header-text pt-2 w-max"
                                >
                                    {{ item.header || 'DEFAULT_KEY' | translate }}
                                </div>
                            </div>
                            <div
                                *ngIf="item.subheader"
                                class="submenu-subheader-text w-auto p-3 text-sm font-semibold"
                            >
                                {{ item.subheader || 'DEFAULT_KEY' | translate }}
                            </div>
                        </ng-container>

                        <!-- SubmenuSection: Section with link and potential sub-items -->
                        <ng-container *ngIf="isSubmenuSection(item)">
                            <a
                                *ngIf="item.link && item.label"
                                [routerLink]="item.link"
                                routerLinkActive="submenu-section-link-active"
                                [routerLinkActiveOptions]="{ exact: item.exact !== undefined ? item.exact : true }"
                                [queryParams]="item.linkParams ?? {}"
                                (click)="toggleSection($event, 'submenu-id-' + i)"
                                class="submenu-section-link w-full font-regular no-underline py-1 mb-1 white-space-nowrap overflow-hidden text-overflow-ellipsis"
                                [ngClass]="{
                                    'pl-5': item.indent,
                                    'pl-3': !item.indent,
                                    'mb-3': item.items && item.items.length > 0
                                }"
                                [attr.id]="'submenu-id-' + i"
                            >
                                <!-- below includes implemnetation for tieredMenu, this will be included in later iterations -->
                                <fa-icon
                                    *ngIf="item.icon"
                                    [icon]="item.icon"
                                    size="1x"
                                    class="mr-2"
                                ></fa-icon>
                                {{ item.label | translate }}
                                <span
                                    *ngIf="item.items && item.items.length > 0"
                                    class="dropdown-indicator"
                                >
                                    <fa-icon
                                        [icon]="
                                            isSectionExpanded('submenu-id-' + i)
                                                ? icons.dropDown
                                                : icons.dropRight
                                        "
                                        size="1x"
                                        class="ml-2"
                                    ></fa-icon>
                                </span>
                            </a>

                            <ng-container
                                *ngIf="
                                    item.items &&
                                    item.items.length > 0 &&
                                    isSectionExpanded('submenu-id-' + i)
                                "
                            >
                                <ng-container *ngFor="let subItem of item.items">
                                    <a
                                        [routerLink]="subItem.link"
                                        routerLinkActive="submenu-section-link-active"
                                        class="submenu-section-sublink font-regular no-underline w-full ml-5 px-3 py-2 mb- 2"
                                    >
                                        {{ subItem.label ?? '' | translate }}
                                    </a>

                                    <ng-container *ngFor="let subSubItem of subItem.subItems">
                                        <a
                                            [routerLink]="subSubItem.link"
                                            routerLinkActive="submenu-section-link-active"
                                            class="submenu-section-subsublink font-regular no-underline w-full ml-5 px-3 py-2 mb-2"
                                        >
                                            {{ subSubItem.label ?? '' | translate }}
                                        </a>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>

                        <!-- Divider -->
                        <ng-container *ngIf="isDivider(item)">
                            <p-divider class="w-full"> </p-divider>
                            <div
                                class="submenu-header-text flex w-full p-0 text-sm font-semibold pl-3 mb-2 mt-3"
                                *ngIf="item.header"
                            >
                                <fa-icon
                                    *ngIf="item.icon"
                                    [icon]="item.icon"
                                    class="submenu-header-icon mr-2"
                                ></fa-icon>
                                {{ item.header || 'DEFAULT_KEY' | translate }}
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isWalletTitle(item)">
                            <div class="w-full pt-3 pr-3 text-sm font-semibold flex">
                                <div *ngIf="item.icon" class="flex align-items-start">
                                    <svg
                                        width="20"
                                        height="30"
                                        viewBox="0 0 20 30"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="pb-2 pr-2"
                                    >
                                        <circle cx="5" cy="5" r="5" fill="#2B4346" />
                                        <circle cx="5" cy="15" r="5" fill="#2B4346" />
                                        <circle cx="5" cy="25" r="5" fill="#2B4346" />
                                        <circle cx="15" cy="5" r="5" fill="#2B4346" />
                                        <circle cx="15" cy="15" r="5" fill="#2B4346" />
                                    </svg>
                                </div>
                                <div class="flex flex-column gap-2">
                                    <div
                                        *ngIf="item.header"
                                        class="submenu-avatar-header-text text-xl"
                                    >
                                        {{ item.header || 'DEFAULT_KEY' | translate }}
                                    </div>
                                    <div
                                        *ngIf="item.subheader"
                                        class="submenu-header-text w-full text-sm font-semibold"
                                    >
                                        {{ item.subheader || 'DEFAULT_KEY' | translate }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>

        <!-- Button for Expand / Collapse Menu -->
        <div class="submenu-btn-expand absolute" (click)="toggleSubmenu()">
            <fa-icon [icon]="isSubmenuExpanded ? icons.collapse : icons.expand"></fa-icon>
        </div>

        <!-- Call to Action / Extended Options -->
        <div class="submenu-new flex relative justify-content-center w-full" *ngIf="activeCta?.label">
            <div class="submenu-new-fade absolute left-0 w-full h-10rem pointer-events-none"></div>
            <div class="relative flex align-items-start justify-content-center flex-column pb-4 pt-2">
                <fsco-navigation-menu
                    *ngIf="ctaMenuActive"
                    [items]="activeCta?.items || []"
                    [positionVertical]="ctaMenuPositionVertical"
                    [positionHorizontal]="ctaMenuPositionHorizontal"
                    (onMenuItemSelected)="closeMenu()"
                    [spaceBottom]="2"
                ></fsco-navigation-menu>
                <p-button
                    *ngIf="isSubmenuExpanded"
                    [outlined]="true"
                    label="{{ activeCta?.label ?? '' | translate }}"
                    (click)="contextMenu($event)"
                ></p-button>
            </div>
        </div>

    </div>
</ng-container>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
    selector: 'fsco-interactive-onboarding-card',
    templateUrl: './interactive-onboarding-card.component.html',
    styleUrls: ['./interactive-onboarding-card.component.scss'],
})
export class InteractiveOnboardingCardComponent {
    @Input() title!: string;
    @Input() description!: string;
    @Input() icon!: IconProp;
    @Input() actionButtonText!: string;
    @Input() actionButtonIcon?: IconProp;

    @Output() createNew: EventEmitter<void> = new EventEmitter();

    onCreateNewClick(): void {
        this.createNew.emit();
    }
}

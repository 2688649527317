import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '@shared/auth/services/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export abstract class PortalApiService<T, R> {
    protected constructor(
        protected httpClient: HttpClient,
        protected auth: AuthService,
        protected apiBaseUrl: string,
        protected sgFragment: string,
        protected plFragment: string,
    ) { }

    protected handleErrors<T>(obs: Observable<T>): Observable<T> {
        return obs.pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // Unauthorized access, redirect to login
                    this.auth.redirectToLogin();
                }
                // Rethrow the error to be handled further or shown in the UI if necessary
                return throwError(error);
            })
        );
    }

    public get<TResponse = R>(options?: { path?: string; params?: any }): Observable<TResponse> {
        const path = options?.path ?? '';
        const params = options?.params ?? {};
        return this.handleErrors(
            this.httpClient.get<TResponse>(`${this.apiBaseUrl}/${this.plFragment}/${path}`, {
                headers: this.auth.authHeaders(),
                params: params,
            }).pipe(map((result) => result as TResponse))
        );
    }

    public getById(id: string | number, options?: { params?: any }): Observable<T> {
        const params = options?.params ?? {};
        return this.handleErrors(
            this.httpClient.get<T>(`${this.apiBaseUrl}/${this.plFragment}/${id}`, { headers: this.auth.authHeaders(), params: params })
                .pipe(map((result) => result as T))
        );
    }

    public create<TResponse = T>(resource: Partial<T>): Observable<TResponse> {
        return this.handleErrors(
            this.httpClient.post<TResponse>(`${this.apiBaseUrl}/${this.plFragment}`, resource, { headers: this.auth.authHeaders() })
                .pipe(map((result) => result as TResponse))
        );
    }

    public update(resource: T & { id: string | number }): Observable<T> {
        return this.handleErrors(
            this.httpClient.put<T>(`${this.apiBaseUrl}/${this.plFragment}/${resource.id}`, resource, { headers: this.auth.authHeaders() })
                .pipe(map((result) => result as T))
        );
    }

    public updatePartial<TResponse = T>(resource: Partial<T> & { id: string | number }): Observable<TResponse> {
        return this.handleErrors(
            this.httpClient.patch<TResponse>(`${this.apiBaseUrl}/${this.plFragment}/${resource.id}`, resource, { headers: this.auth.authHeaders() })
                .pipe(map((result) => result as TResponse))
        );
    }

    public delete(id: string | number): Observable<void> {
        return this.handleErrors(
            this.httpClient.delete<void>(`${this.apiBaseUrl}/${this.plFragment}/${id}`, { headers: this.auth.authHeaders() })
        );
    }

    public search(options: { params?: any }): Observable<T[]> {
        const params = options?.params ?? {};
        return this.handleErrors(
            this.httpClient.get<T[]>(`${this.apiBaseUrl}/${this.plFragment}/search`, { headers: this.auth.authHeaders(), params: params })
                .pipe(map((result) => result as T[]))
        );
    }
}

<div
    class="file-uploader border-dashed border-3 surface-border border-round-xl p-5 text-center h-full"
    [class.file-uploader-drop-active]="isDragOver"
>
    <div
        class="file-uploader-drag-drop flex flex-column align-items-center justify-content-center p-ai-center border-round-xl h-full"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
        (dragleave)="onDragLeave()"
        (click)="triggerFileInputClick($event)"
        *ngIf="!uploading && (!files || files.length < 1)"
    >
        <fa-icon [icon]="iconUpload" size="3x" class="mt-6 mb-3"></fa-icon>
        <h3 class="text-1xl font-semibold mb-4">Drag & drop files or <strong class="underline">Browse</strong></h3>
        <div class="text-sm text-secondary mb-4">Supported formats: {{ acceptMessage }}</div>
        <div class="text-sm text-secondary mb-5">Max file size {{ formatBytes(maxFileSize) }}</div>
    </div>
    <input
        #fileInput
        type="file"
        [multiple]="multiple"
        [attr.accept]="acceptAttribute"
        (change)="onFileSelected($event)"
        style="display: none"
    />
    <div
        class="file-uploader-success w-full py-5"
        *ngIf="!uploading && files && files.length > 0"
        (dragover)="onDragOver($event)"
        (drop)="onDrop($event)"
        (dragleave)="onDragLeave()"
        (click)="triggerFileInputClick($event)"
    >
        <div class="file-uploader-success-icon">
            <fa-icon [icon]="iconSuccess" size="3x" class="mt-6 mb-3"></fa-icon>
        </div>
        <h2 class="mb-6 text-lg font-semibold">File selected</h2>
        <div *ngIf="unsupportedFilesMessage" class="unsupported-files-message grid justify-content-center align-items-center mb-6">
            <div class="unsupported-files-message w-5 text-sm">
                {{ unsupportedFilesMessage }}
            </div>
        </div>
        <div class="file-uploader-success-files flex flex-column justify-content-center align-items-center text-sm">
            <div
                *ngFor="let file of files; let i = index"
                class="file-uploader-success-files-item grid w-5 p-2 mb-3 border-solid border-1 surface-border border-round-xl text-left"
            >
                <div
                    class="file-uploader-success-files-item-content col-11 white-space-nowrap overflow-hidden font-semibold text-overflow-ellipsis text-left text-base"
                >
                    {{ file.name }}
                    <div class="file-uploader-success-files-item-content-size text-sm">File size {{ formatBytes(file.size) }}</div>
                </div>
                <div class="file-uploader-success-files-item-content-icon col-1" (click)="removeFile($event, i)">
                    <fa-icon [icon]="iconDelete" size="xs"></fa-icon>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="uploading" class="flex flex-column align-items-center justify-content-center h-full">
        <div>
            <fsco-loader-spinner></fsco-loader-spinner>
        </div>
        <div class="mt-4 text-lg font-normal">Uploading...</div>
    </div>
</div>

/**
 * Webhooks Outbound Portal API
 * This specifies the Webhook Outbound endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export enum WebhookOutboundInvokeStatus {
    Pending = <any> 'pending',
    Success = <any> 'success',
    Failed = <any> 'failed',
    Maxretriesexceeded = <any> 'maxretriesexceeded',
    Cancelled = <any> 'cancelled'
}

/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class DocumentPrompt {
    'id': string;
    'name': string;
    'format': string;
    'description'?: string;
    'itemType'?: DocumentPrompt.ItemTypeEnum;
    'fieldType': DocumentPrompt.FieldTypeEnum;
    'llmModel'?: string;
    'llmProvider'?: string;
    'children'?: Array<DocumentPrompt>;
    'required'?: boolean;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "format",
            "baseName": "format",
            "type": "string"
        },
        {
            "name": "description",
            "baseName": "description",
            "type": "string"
        },
        {
            "name": "itemType",
            "baseName": "itemType",
            "type": "DocumentPrompt.ItemTypeEnum"
        },
        {
            "name": "fieldType",
            "baseName": "fieldType",
            "type": "DocumentPrompt.FieldTypeEnum"
        },
        {
            "name": "llmModel",
            "baseName": "llmModel",
            "type": "string"
        },
        {
            "name": "llmProvider",
            "baseName": "llmProvider",
            "type": "string"
        },
        {
            "name": "children",
            "baseName": "children",
            "type": "Array<DocumentPrompt>"
        },
        {
            "name": "required",
            "baseName": "required",
            "type": "boolean"
        }    ];

    static getAttributeTypeMap() {
        return DocumentPrompt.attributeTypeMap;
    }
}

export namespace DocumentPrompt {
    export enum ItemTypeEnum {
        SingleLine = <any> 'singleLine',
        MultiLine = <any> 'multiLine',
        DateTime = <any> 'dateTime',
        Name = <any> 'name',
        Email = <any> 'email',
        Phone = <any> 'phone',
        Address = <any> 'address',
        Table = <any> 'table',
        List = <any> 'list',
        Float = <any> 'float',
        Currency = <any> 'currency'
    }
    export enum FieldTypeEnum {
        Integer = <any> 'integer',
        String = <any> 'string',
        Float = <any> 'float',
        List = <any> 'list'
    }
}

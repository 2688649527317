import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import {
    NavMenuItem,
    PositionHorizontal,
    PositionVertical,
} from '@fsco-components/core/menu/navigation-menu/navigation-menu.model';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import {
    Submenu,
    SubmenuCta,
    SubmenuDivider,
    SubmenuItemType,
    SubmenuSection,
    SubmenuTitle,
    SubmenuTitleAccount,
    SubmenuTitleAvatar,
} from './sub-menu-model';
import { SubMenuService } from './sub-menu.service';

@Component({
    selector: 'fsco-sub-menu',
    templateUrl: './sub-menu.component.html',
    styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit, OnDestroy, AfterViewInit {
    @Output() submenuToggle = new EventEmitter<boolean>();

    activeMenuItem: MenuItem | null = null;
    activeSubMenu: SubmenuItemType[] = [];
    activeCta!: SubmenuCta | null;
    expandedSections: string[] = [];
    isSubmenuExpanded = true;
    menuItems: NavMenuItem[] = [];
    ctaMenuActive: boolean = false;
    ctaMenuPositionVertical: PositionVertical = PositionVertical.Above;
    ctaMenuPositionHorizontal: PositionHorizontal = PositionHorizontal.Left;

    icons = {
        dropDown: faChevronDown,
        dropRight: faChevronRight,
        collapse: faChevronLeft,
        expand: faChevronRight,
    };

    subscription: Subscription;

    constructor(private subMenu: SubMenuService) {
        this.subscription = new Subscription();
    }

    ngOnInit() {
        this.subscription = this.subMenu.currentSubmenu.subscribe((data: Submenu) => {
            this.activeSubMenu = data.items;
            this.activeCta = data?.cta || null;
        });
        this.isSubmenuExpanded = true;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.submenuToggle.emit(this.isSubmenuExpanded);
    }

    isSubmenuTitle(item: any): item is SubmenuTitle {
        return item instanceof SubmenuTitle;
    }

    isSubmenuAvatarTitle(item: any): item is SubmenuTitleAvatar {
        return item instanceof SubmenuTitleAvatar;
    }

    isSubmenuSection(item: any): item is SubmenuSection {
        return item instanceof SubmenuSection;
    }

    isDivider(item: any): item is SubmenuDivider {
        return item instanceof SubmenuDivider;
    }

    isWalletTitle(item: any): item is SubmenuTitleAccount {
        return item instanceof SubmenuTitleAccount;
    }

    getFirstLetterUpper(value: string): string {
        return value ? value.charAt(0).toUpperCase() : 'U';
    }

    // toggleSection includes implemnetation for tieredMenu, this will be included in later iterations
    toggleSection(event: MouseEvent, itemId: string): void {
        event.preventDefault();
        event.stopPropagation();

        const index = this.expandedSections.indexOf(itemId);
        if (index >= 0) {
            this.expandedSections.splice(index, 1);
        } else {
            this.expandedSections.push(itemId);
        }
    }

    closeMenu() {
        this.ctaMenuActive = false;
    }

    toggleSubmenu() {
        this.isSubmenuExpanded = !this.isSubmenuExpanded;
        this.submenuToggle.emit(this.isSubmenuExpanded);
    }

    isSectionExpanded(itemId: string): boolean {
        return this.expandedSections.includes(itemId);
    }

    contextMenu(event: MouseEvent): void {
        event.stopPropagation();
        this.ctaMenuActive = !this.ctaMenuActive;
    }
}

/**
 * Documents Portal API
 * This document specifies the Document endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentDataLlmValue } from './documentDataLlmValue';
import type { DocumentFileData } from './documentFileData';
import type { DocumentGroupPartial } from './documentGroupPartial';
import type { DocumentPrompt } from './documentPrompt';
import type { DocumentStatus } from './documentStatus';
import type { DocumentTemplate } from './documentTemplate';

export class Document {
    'id': string;
    'uploadBatchId'?: string;
    'refId'?: string;
    'docType': string;
    'docName': string;
    'docExt': string;
    'source'?: string;
    'isPublic'?: boolean;
    'isUploaded'?: boolean;
    'fileData'?: DocumentFileData;
    'webhookId'?: boolean;
    'template'?: DocumentTemplate;
    'prompts'?: Array<DocumentPrompt>;
    'groups'?: DocumentGroupPartial;
    'ocrData'?: { [key: string]: DocumentDataLlmValue; };
    'downloadUrl'?: string;
    'status'?: DocumentStatus;
    'uploadUrl'?: string;
    'createdAt': string;
    'updatedAt'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "uploadBatchId",
            "baseName": "uploadBatchId",
            "type": "string"
        },
        {
            "name": "refId",
            "baseName": "refId",
            "type": "string"
        },
        {
            "name": "docType",
            "baseName": "docType",
            "type": "string"
        },
        {
            "name": "docName",
            "baseName": "docName",
            "type": "string"
        },
        {
            "name": "docExt",
            "baseName": "docExt",
            "type": "string"
        },
        {
            "name": "source",
            "baseName": "source",
            "type": "string"
        },
        {
            "name": "isPublic",
            "baseName": "isPublic",
            "type": "boolean"
        },
        {
            "name": "isUploaded",
            "baseName": "isUploaded",
            "type": "boolean"
        },
        {
            "name": "fileData",
            "baseName": "fileData",
            "type": "DocumentFileData"
        },
        {
            "name": "webhookId",
            "baseName": "webhookId",
            "type": "boolean"
        },
        {
            "name": "template",
            "baseName": "template",
            "type": "DocumentTemplate"
        },
        {
            "name": "prompts",
            "baseName": "prompts",
            "type": "Array<DocumentPrompt>"
        },
        {
            "name": "groups",
            "baseName": "groups",
            "type": "DocumentGroupPartial"
        },
        {
            "name": "ocrData",
            "baseName": "ocrData",
            "type": "{ [key: string]: DocumentDataLlmValue; }"
        },
        {
            "name": "downloadUrl",
            "baseName": "downloadUrl",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "DocumentStatus"
        },
        {
            "name": "uploadUrl",
            "baseName": "uploadUrl",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return Document.attributeTypeMap;
    }
}

export namespace Document {
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ComponentSize } from '@fsco-components/core/types/enum';
import { faPlus } from '@fsco/fontawesome-pro/pro-light-svg-icons';

@Component({
    selector: 'fsco-card-compact',
    templateUrl: './card-compact.component.html',
    styleUrls: ['./card-compact.component.scss'],
})
export class CardCompactComponent {
    @Input() createMode: boolean = false;
    @Input() title: string = '';
    @Input() subtitle: string = '';
    @Input() icon: IconProp = faPlus;
    @Input() iconColor: string = 'gray-200';
    @Input() isNew: boolean = false;
    @Input() isUpdating: boolean = false;
    @Output() onMainActionClick = new EventEmitter<void>();

    iconSize: ComponentSize = 'md';

    handleMainClick() {
        this.onMainActionClick.emit();
    }
}

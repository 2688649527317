import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLandscape } from '@fortawesome/pro-light-svg-icons';
import { firstValueFrom } from 'rxjs';
import { EXT_IMAGES, EXT_JSON, EXT_PDF } from './file-preview.model';

@Component({
    selector: 'fsco-file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.scss'],
})
export class FilePreviewComponent implements OnInit {
    @Input() file!: File;
    @Input() remoteUrl?: string;
    @Input() extension?: string;
    @Input() maxHeight?: string;
    @Input() minHeight?: string;
    @Input() height?: string;

    isRemoteUrl: boolean = false;
    iconPreview: IconProp = faLandscape;
    boxPreview: SafeResourceUrl | undefined;
    isImage: boolean = false;
    isPdf: boolean = false;
    isJson: boolean = false;
    isFilePreviewable: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private http: HttpClient,
    ) {}

    ngOnInit(): void {
        if (this.file) {
            this.previewFile(this.file);
        } else if (this.remoteUrl) {
            this.isRemoteUrl = true;
            const that = this;
            if (!this.remoteUrl.includes('/')) {
                this.isFilePreviewable = false;
                return;
            }
            this.testFilePreviewable(this.remoteUrl).then((previewable) => {
                this.isFilePreviewable = previewable;
                if (this.isFilePreviewable) {
                    that.previewRemoteUrl(that.remoteUrl!);
                }
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.remoteUrl) {
            this.previewRemoteUrl(this.remoteUrl);
        }
        if (this.file) {
            this.previewFile(this.file);
        }
    }

    private async testFilePreviewable(remoteUrl: string): Promise<boolean> {
        try {
            await firstValueFrom(this.http.get(remoteUrl));
            return true;
        } catch (error: any) {
            return error.status === 200;
        }
    }

    previewFile(file: File) {
        if (!file) return;

        const reader = new FileReader();

        reader.onload = () => {
            this.isImage = file.type.startsWith('image/');
            this.isPdf = file.type.startsWith('application/pdf');
            this.isJson = file.type.startsWith('application/json');

            if (this.isImage || this.isPdf) {
                this.boxPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
                    reader.result as string,
                );
            } else if (this.isJson && this.remoteUrl) {
                this.http.get(this.remoteUrl).subscribe((data) => {
                    this.boxPreview = data;
                });
            }
        };

        reader.readAsDataURL(file);
    }

    previewRemoteUrl(url: string) {
        if (!this.isFilePreviewable) return;
        const extension = this.extension ?? url.split('.').pop()?.toLowerCase();

        if (extension) {
            this.isImage = EXT_IMAGES.includes(extension) || extension.startsWith('image/');
            this.isPdf = EXT_PDF.includes(extension);
            this.isJson = EXT_JSON.includes(extension);
        }

        if (this.isImage || this.isPdf) {
            this.boxPreview = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else if (this.isJson && this.remoteUrl) {
            this.http.get(this.remoteUrl).subscribe((data) => {
                this.boxPreview = data;
            });
        }
    }

    setClass() {
        let customClass = '';
        if (this.maxHeight) customClass += `max-h-${this.maxHeight} `;
        if (this.minHeight) customClass += `min-h-${this.minHeight} `;
        if (this.height) customClass += `h-${this.height} `;
        return customClass ?? 'h-full';
    }
}

export enum SelectorButtonTemplate {
  Button = 'button',
  Pill = 'pill',
}

export interface SelectorButtonItem {
  label: string;
  id: string;
  icon?: string;
  styleClass?: string;
  selected?: boolean;
  data?: {
    [key: string]: any;
  };
}

<div *ngIf="showHeader" class="flex justify-content-between mt-3 mb-3">
    <p-skeleton width="9rem" height="2rem"></p-skeleton>
    <p-skeleton width="5rem" height="2rem"></p-skeleton>
</div>
<p-table [value]="rowItems">
    <ng-template pTemplate="body">
        <tr>
            <td><p-skeleton></p-skeleton></td>
            <td><p-skeleton></p-skeleton></td>
            <td><p-skeleton></p-skeleton></td>
            <td><p-skeleton></p-skeleton></td>
        </tr>
    </ng-template>
</p-table>

/**
 * Apikeys Portal API
 * This apikey specifies the Apikey endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';

export class ApiKeyPartial {
    /**
    * The id is not allowed to be updated.
    */
    'id': any | null;
    'key'?: string;
    'label': string;
    'createdAt'?: string;
    'lastLoginDate'?: string;
    'isActive'?: boolean;
    'organisationId'?: string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "any"
        },
        {
            "name": "key",
            "baseName": "key",
            "type": "string"
        },
        {
            "name": "label",
            "baseName": "label",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        },
        {
            "name": "lastLoginDate",
            "baseName": "lastLoginDate",
            "type": "string"
        },
        {
            "name": "isActive",
            "baseName": "isActive",
            "type": "boolean"
        },
        {
            "name": "organisationId",
            "baseName": "organisationId",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return ApiKeyPartial.attributeTypeMap;
    }
}


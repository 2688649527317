import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputFieldTypeEnum } from '@shared/form.model';

@Component({
    selector: 'fsco-input-with-dropdown',
    templateUrl: './input-with-dropdown.component.html',
    styleUrls: ['./input-with-dropdown.component.scss'],
})
export class InputWithDropdownComponent implements OnInit {
    @Input() id: string = '';
    @Input() type: InputFieldTypeEnum = InputFieldTypeEnum.Text;
    @Input() borderClass: string = 'border-500';
    @Input() dropdownData: any = [];
    @Input() textPlaceholder!: string | number;
    @Input() dropdownPlaceholder: string = '';
    @Output() onCustomEvent = new EventEmitter<any>();
    @Input() disableTextInput: boolean = false;
    @Input() disableDropdown: boolean = false;
    @Input() textInputData: any;

    units: any[] = [];
    selectedUnit: string = '';

    ngOnInit(): void {
        this.units = [...this.dropdownData];
    }

    emitCustomEvent(): void {
        this.onCustomEvent.emit({
            textInputData: this.textInputData,
            selectedUnit: this.selectedUnit,
        });
    }
}

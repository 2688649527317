<ng-container>
    <fsco-app-loader *ngIf="loading"></fsco-app-loader>
    <div *ngIf="appChecksIsLoggedIn(); else notLoggedIn">
        <cp-nav></cp-nav>
    </div>
    <ng-template #notLoggedIn>
        <router-outlet></router-outlet>
    </ng-template>
    <fsco-confirm-dialog></fsco-confirm-dialog>
</ng-container>

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "fsco-app-loader",
  templateUrl: "./loader-app.component.html",
  styleUrls: ["./loader-app.component.scss"],
})
export class LoaderAppComponent implements OnInit {
  ngOnInit(): void {}
}

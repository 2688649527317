import { Injectable } from '@angular/core';
import {
    Submenu
} from '@fsco-components/page-structure/sub-menu/sub-menu-model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SubMenuService {
    submenuSource;
    currentSubmenu;

    constructor() {
        this.submenuSource = new BehaviorSubject<Submenu>({ items: [] });
        this.currentSubmenu = this.submenuSource.asObservable();
    }

    /**
     * @description Set the new group of items for render on submenu component
     * @param params.items group of items for set the new submenu
     * @param params.cta options on CTA button (bottom)
     */
    set(params: Submenu) {
        this.submenuSource.next(params);
    }

    /**
     * @description Clean the menu items
     */
    clear() {
        this.submenuSource.next({ items: [] });
    }

    /**
     * Can get any of the resources and its properties,
     * usefull when needs to reuse the links or items in
     * another internal components
     * @returns the current submenu
     */
    getSubmenu() {
        return this.submenuSource.getValue();
    }
}

import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Organisation, OrganisationsPaginatedResult, Subscription, User } from '@portal-api-models';
import { AuthService } from '@shared/auth/services/auth.service';
import { LoggerService } from '@shared/log.service';
import { PortalApiService } from '@shared/portal-api/portal-api.service';
import { PORTAL_API_BASE_URL } from '../app.module';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionService } from './session.service';

@Injectable({ providedIn: 'root' })
export class OrganisationService extends PortalApiService<Organisation, OrganisationsPaginatedResult> {
    constructor(
        private logger: LoggerService,
        protected override auth: AuthService,
        private sessionService: SessionService,
        protected http: HttpClient,
        @Inject(PORTAL_API_BASE_URL) protected override apiBaseUrl: string,
    ) {
        super(http, auth, apiBaseUrl, 'organisation', 'organisations');
        this.logger.setContext(OrganisationService.name);
    }

    public getMe(): Observable<Organisation> {
        return this.handleErrors(
            this.httpClient
                .get<Organisation>(`${this.apiBaseUrl}/${this.plFragment}/me`, { headers: this.auth.authHeaders() })
                .pipe(map((result) => result as Organisation))
        );
    }
    public getSubscription(): Observable<Subscription> {
        return this.handleErrors(
            this.httpClient
                .get<Subscription>(`${this.apiBaseUrl}/${this.plFragment}/subscription`, { headers: this.auth.authHeaders() })
                .pipe(map((result) => {
                    const session = this.sessionService.get()
                    session.subscription = result
                    this.sessionService.set(session)
                    return result as Subscription
                }))
        );
    }
}

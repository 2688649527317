import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'fsco-loader-spinner',
    templateUrl: './loader-spinner.component.html',
    styleUrls: ['./loader-spinner.component.scss'],
})
export class LoaderSpinnerComponent implements OnInit {
    @Input() size: 'xs' | 'sm' | 'md' | 'lx' = 'md';
    @Input() bColor: string = '#51797d';

    ngOnInit(): void {}

    getSize(): string {
        return this.size;
    }
}

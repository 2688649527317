import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'cp-logout',
    templateUrl: './logout.html',
    styleUrls: ['./logout.scss'],
})
export class LogoutComponent {
    constructor(private authService: AuthService) {}

    async ngOnInit() {
        this.authService.redirectToLogout();
    }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { faCheckCircle, faFileUpload, faTrashCan } from '@fortawesome/pro-light-svg-icons';

@Component({
    selector: 'fsco-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit, OnDestroy {
    @Input() files: File[] = [];
    @Input() multiple: boolean = false;
    @Input() accept: string[] = ['jpg', 'jpeg', 'png', 'pdf'];
    @Input() maxFileSize: number = 5000000;
    @Input() uploading: boolean = false;

    @Output() filesSelected = new EventEmitter<File[]>();
    @Output() fileRemoved = new EventEmitter<number>();

    @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

    acceptAttribute?: string;
    acceptMessage?: string;
    iconUpload = faFileUpload;
    iconSuccess = faCheckCircle;
    iconDelete = faTrashCan;
    isDragOver = false;
    unsupportedFilesMessage: string = '';

    ngOnInit(): void {
        this.acceptAttribute = this.formatAcceptAttribute(this.accept);
        this.acceptMessage = this.formatAcceptMessage(this.accept);
    }

    ngOnDestroy(): void {
        this.files = [];
    }

    /**
     * @description Activate class when drag over
     * @param event DragEvent
     * @returns void
     */
    onDragOver(event: DragEvent): void {
        event.preventDefault();
        this.isDragOver = true;
    }

    /**
     * Resets the drag over state when the dragged items leave the drop area.
     * @param event The drag event
     */
    onDragLeave(): void {
        this.isDragOver = false;
    }

    /**
     * Handles the drop event by preventing the default browser behavior,
     * resetting drag over state, and adding the dropped files.
     * @param event The drop event
     */
    onDrop(event: DragEvent): void {
        event.preventDefault();
        this.isDragOver = false;
        this.resetMessages();
        const files = event.dataTransfer?.files;
        if (files) {
            this.addFiles(files);
        }
    }

    /**
     * Handles file selection from the input element and adds the selected files.
     * @param event The file input change event
     */
    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.resetMessages();
        if (input.files) {
            this.addFiles(input.files);
        }
    }

    /**
     * Resets any messages related to file support.
     */
    resetMessages(): void {
        this.unsupportedFilesMessage = '';
    }

    /**
     * Checks if the file type is allowed based on the accepted extensions.
     * @param file The file to check
     * @returns True if the file type is allowed, false otherwise
     */
    isFileTypeAllowed(file: File): boolean {
        const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
        return this.accept.includes(fileExtension);
    }

    /**
     * Adds files to the component state, checking for unsupported or oversized files.
     * Updates the unsupported files message if necessary.
     * @param selectedFiles The list of files to add
     */
    addFiles(selectedFiles: FileList): void {
        const unsupportedFiles = [];
        const oversizedFiles = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (this.isFileTypeAllowed(file)) {
                this.files.push(file);
            } else if (file.size > this.maxFileSize) {
                oversizedFiles.push(file.name);
            } else {
                unsupportedFiles.push(file.name);
            }
        }

        if (unsupportedFiles.length > 0) {
            this.unsupportedFilesMessage = `The following files are not supported: ${unsupportedFiles.join(', ')}`;
        }

        if (oversizedFiles.length > 0) {
            this.unsupportedFilesMessage += ` The following files exceed the maximum size: ${oversizedFiles.join(', ')}`;
        }

        this.filesSelected.emit(this.files);
    }

    /**
     * Formats the accepted extensions into a comma-separated string for the input accept attribute.
     * @param extensions Array of accepted file extensions
     * @returns A formatted string for the accept attribute
     */
    formatAcceptAttribute(extensions: string[]): string {
        return extensions.map((ext) => '.' + ext).join(',');
    }

    /**
     * Formats the accepted extensions into a user-friendly string.
     * @param extensions Array of accepted file extensions
     * @returns A formatted string for display
     */
    formatAcceptMessage(extensions: string[]): string {
        return extensions.map((ext) => ext.toUpperCase()).join(', ');
    }

    /**
     * Converts bytes to a human-readable format.
     * @param bytes Number of bytes
     * @param decimals Number of decimal places to format to
     * @returns Formatted size string
     */
    formatBytes(bytes: number, decimals = 1): string {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Triggers the file input click action, allowing users to select files.
     * @param event The click event
     */
    triggerFileInputClick(event: Event): void {
        event.stopPropagation();
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    /**
     * Removes a file from the list of selected files.
     * @param index The index of the file to remove
     */
    removeFile(event: Event, index: number): void {
        event.stopPropagation();
        if (index >= 0 && index < this.files.length) {
            this.files.splice(index, 1);
            this.fileRemoved.emit(index);
        }
    }

    public resetUpload() {
        this.files = [];
        this.fileInput.nativeElement.value = '';
    }
}

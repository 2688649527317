import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { FormsModule } from '@angular/forms';
import { SharedModule } from 'primeng/api';
import core from './.bundle/bundle.core';
import file from './.bundle/bundle.file';
import indicators from './.bundle/bundle.indicators';
import ngxjsonviewer from './.bundle/bundle.ngxjsonviewer';
import structure from './.bundle/bundle.pagestructure';
import prime from './.bundle/bundle.prime';
import { ProcessingFlyoutComponent } from './page-structure/processing-flyout/processing-flyout.component';

@NgModule({
    declarations: [...core, ...structure, ...indicators, ...file, ProcessingFlyoutComponent],
    imports: [
        ...prime,
        ngxjsonviewer,
        TranslateModule,
        CommonModule,
        RouterModule,
        FontAwesomeModule,
        SharedModule,
        FormsModule,
    ],
    exports: [...core, ...structure, ...indicators, ...file, ProcessingFlyoutComponent],
    providers: [],
})
export class FscoComponentsModule {}

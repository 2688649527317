<div
    class="main-menu h-screen fixed top-0 left-0 z-1 surface-border overflow-hidden"
    [ngClass]="{ expanded: isMenuExpanded, collapsed: !isMenuExpanded }"
>
    <div class="flex h-full">
        <div class="flex flex-column h-full w-full flex-shrink-0 select-none" [ngClass]="menu.top?.styles?.default?.backgroundColor">
            <div class="main-menu-top">
                <ul class="list-none p-0 m-0">
                    <li class="flex justify-center justify-content-center align-items-center py-2">
                        <svg width="20" height="30" viewBox="0 0 20 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="5" cy="5" r="5" fill="#2B4346" />
                            <circle cx="5" cy="15" r="5" fill="#2B4346" />
                            <circle cx="5" cy="25" r="5" fill="#2B4346" />
                            <circle cx="15" cy="5" r="5" fill="#2B4346" />
                            <circle cx="15" cy="15" r="5" fill="#2B4346" />
                        </svg>
                    </li>
                    <li [ngClass]="menu.top?.styles?.hover?.iconBackgroundColor" (click)="toggleMenu()">
                        <a
                            pRipple
                            class="flex flex-row sm:flex-column align-items-center cursor-pointer lg:justify-content-center transition-duration-150 transition-colors"
                        >
                            <fa-icon
                                [ngClass]="menu.top?.styles?.default?.iconColor"
                                class="mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl main-menu-icon"
                                [icon]="icons.collapse"
                            ></fa-icon>
                        </a>
                    </li>
                </ul>
                <ul class="list-none p-0 m-0">
                    <li
                        *ngFor="let item of menu.top?.items"
                        [ngClass]="menu.top?.styles?.hover?.iconBackgroundColor"
                        class="main-menu-item"
                        [routerLink]="item.link"
                        [queryParams]="item.linkParams"
                        routerLinkActive="active"
                        #rla="routerLinkActive"
                        [routerLinkActiveOptions]="{ exact: item.exactMatch }"
                        [pTooltip]="!isMenuExpanded ? tooltipContent : ''"
                    >
                        <a
                            pRipple
                            [ngClass]="rla.isActive ? menu.top?.styles?.active?.backgroundColor : ''"
                            class="flex flex-row sm:flex-column align-items-center cursor-pointer lg:justify-content-center transition-duration-150 transition-colors"
                        >
                            <fa-icon
                                [ngClass]="menu.top?.styles?.default?.iconColor"
                                class="mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl main-menu-icon"
                                *ngIf="item.icon"
                                [icon]="item.icon"
                            ></fa-icon>
                            <span
                                [ngClass]="menu.top?.styles?.default?.textColor"
                                class="main-menu-item-text font-medium inline text-base sm:text-xs lg:block"
                            >
                                {{ item.label ?? '' | translate }}
                            </span>
                        </a>
                        <ng-template #tooltipContent>
                            <div class="text-xs">{{ item.label ?? '' | translate }}</div>
                        </ng-template>
                    </li>
                </ul>
            </div>
            <div class="main-menu-bottom mt-auto" [ngClass]="menu.bottom?.styles?.default?.backgroundColor">
                <ul class="list-none p-0 m-0">
                    <li
                        *ngFor="let item of menu.bottom?.items"
                        [ngClass]="menu.bottom?.styles?.hover?.iconBackgroundColor"
                        class="main-menu-item"
                        [routerLink]="item.link"
                        routerLinkActive="active"
                        #rla="routerLinkActive"
                        [routerLinkActiveOptions]="{ exact: item.exactMatch }"
                        [pTooltip]="!isMenuExpanded ? tooltipContent : ''"
                    >
                        <a
                            pRipple
                            [ngClass]="rla.isActive ? menu.bottom?.styles?.active?.backgroundColor : ''"
                            class="flex flex-row lg:flex-column align-items-center cursor-pointer lg:justify-content-center transition-duration-150 transition-colors"
                        >
                            <fa-icon
                                [ngClass]="menu.bottom?.styles?.default?.iconColor"
                                class="mr-2 lg:mr-0 mb-0 lg:mb-2 text-base lg:text-2xl main-menu-icon"
                                *ngIf="item.icon"
                                [icon]="item.icon"
                            ></fa-icon>
                            <span
                                [ngClass]="menu.bottom?.styles?.default?.textColor"
                                class="main-menu-item-text font-regular inline text-base lg:text-xs lg:block"
                                >{{ item.label ?? '' | translate }}</span
                            >
                        </a>
                        <ng-template #tooltipContent>
                            <div class="text-xs">{{ item.label ?? '' | translate }}</div>
                        </ng-template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- No set padding here, could affect other elements-->
<div
    class="main-content-window w-full flex"
    [ngClass]="{ expanded: isMenuExpanded, collapsed: !isMenuExpanded, 'submenu-activated': subMenuActived, 'topbar-activated':topBarExpanded }"
>
    <fsco-sub-menu *ngIf="subMenuActived" (submenuToggle)="onSubmenuToggle($event)"></fsco-sub-menu>
    <div
        class="main-content-wrap w-full"
        [ngClass]="{ 'main-content-wrap-expanded': !subMenuExpanded, 'main-content-wrap-collapsed': subMenuExpanded }"
    >
        <div class="main-content-outlet">
            <fsco-breadcrumbs *ngIf="!subMenuActived"></fsco-breadcrumbs>
            <router-outlet></router-outlet>
        </div>
        <fsco-footer></fsco-footer>
    </div>
</div>

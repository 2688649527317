import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'fsco-skeleton-grid',
    templateUrl: './skeleton-grid.component.html',
    styleUrls: ['./skeleton-grid.component.scss'],
})
export class SkeletonGridComponent implements OnChanges {
    @Input() items: number = 5;
    @Input() showHeader: boolean = true;

    rowItems!: any[];

    constructor() {
        this.buildItems();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this.buildItems();
        }
    }

    buildItems() {
        this.rowItems = new Array(this.items).fill({});
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbsService {
    private breadcrumbsSubject = new BehaviorSubject<Array<Breadcrumb>>([]);
    public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

    constructor(private router: Router) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            const root = this.router.routerState.snapshot.root;
            const breadcrumbs = this.createBreadcrumbs(root);
            this.breadcrumbsSubject.next(breadcrumbs);
        });
    }

    private createBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
        if (route) {
            const path = route.routeConfig?.path;
            const nextUrl = `${url}${path ? '/' + path : ''}`;

            if (route.routeConfig?.data?.breadcrumb) {
                breadcrumbs.push({ label: route.routeConfig.data.breadcrumb, url: nextUrl });
            }

            if (route.firstChild) {
                if (route.firstChild.routeConfig?.path?.includes(':id')) {
                    const id = route.firstChild.params.id;
                    breadcrumbs.push({ label: `${id}`, url: nextUrl + `/${id}`, isId: true });
                } else {
                    return this.createBreadcrumbs(route.firstChild, nextUrl, breadcrumbs);
                }
            }
        }
        return breadcrumbs;
    }
}

interface Breadcrumb {
    label: string;
    url: string;
    isId?: boolean;
}

/**
 * Wallets Portal API
 * This specifies the Wallet endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { AssetMetadata } from './assetMetadata';
import type { AssetStatus } from './assetStatus';
import type { AssetType } from './assetType';
import type { Listing } from './listing';

export class Asset {
    'walletAddress': string;
    'type': AssetType;
    'metadata': AssetMetadata;
    'collectionId'?: string;
    'id': string;
    'tokenId'?: string;
    'status': AssetStatus;
    'soldListings'?: Array<Listing>;
    'boughtListings'?: Array<Listing>;
    'currentListings'?: Array<Listing>;
    'pastListings'?: Array<Listing>;
    'updatedAt'?: string;
    'createdAt': string;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "walletAddress",
            "baseName": "walletAddress",
            "type": "string"
        },
        {
            "name": "type",
            "baseName": "type",
            "type": "AssetType"
        },
        {
            "name": "metadata",
            "baseName": "metadata",
            "type": "AssetMetadata"
        },
        {
            "name": "collectionId",
            "baseName": "collectionId",
            "type": "string"
        },
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "tokenId",
            "baseName": "tokenId",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "AssetStatus"
        },
        {
            "name": "soldListings",
            "baseName": "soldListings",
            "type": "Array<Listing>"
        },
        {
            "name": "boughtListings",
            "baseName": "boughtListings",
            "type": "Array<Listing>"
        },
        {
            "name": "currentListings",
            "baseName": "currentListings",
            "type": "Array<Listing>"
        },
        {
            "name": "pastListings",
            "baseName": "pastListings",
            "type": "Array<Listing>"
        },
        {
            "name": "updatedAt",
            "baseName": "updatedAt",
            "type": "string"
        },
        {
            "name": "createdAt",
            "baseName": "createdAt",
            "type": "string"
        }    ];

    static getAttributeTypeMap() {
        return Asset.attributeTypeMap;
    }
}

export namespace Asset {
}

<div class="w-full p-d-flex p-flex-column p-ai-center p-mt-4 font-regular h-full">
    <!-- Heading with Icon, Title and Button -->
    <div class="flex justify-content-between pt-6 pb-0 px-5 ">
        <div class="flex align-items-center">
            <fa-icon [icon]="icon" class="mr-4" size="2x"></fa-icon>
            <div class="text-4xl font-semibold">
                {{ title }}
            </div>
        </div>
        <div *ngIf="actionButtonText" class="flex justify-content-end align-items-end">
            <p-button (click)="onCreateNewClick()" class="flex ml-2 inline-block">{{
                actionButtonText
            }}</p-button>
        </div>
    </div>

    <!-- Description -->
    <div class="p-mt-2 py-3 ">
        {{ description }}
    </div>

    <!-- Content Projection -->
    <div class="h-full">
        <ng-content></ng-content>
    </div>
</div>

import { ROUTE_PATHS } from '@shared/navigation.service';
import { CardData } from './card-image.model';

export const CARD_DATA: CardData[] = [
    {
        img: '../../../assets/docs-home.svg',
        title: 'Documents',
        subtitle: 'AI-powered data extraction',
        body: 'Save time and data entry burden by extracting key data to improve your business processes with the added benefit of adding to chain!',
        buttonLabel: 'Get started',
        route: ROUTE_PATHS.DOCS_LIST,
        color: '#c0e5dc',
        width: 'lg-break- md-break- sm-break-',
        breakpoints: '1',
    },
    {
        img: '../../../assets/prompt-home.svg',
        title: 'Documents',
        subtitle: 'AI Prompt tester',
        body: 'Test AI prompts in real-time on your documents.',
        buttonLabel: 'Start testing',
        route: ROUTE_PATHS.DOCS_PROMPT_TESTER,
        color: '#c2e9ec',
        width: 'lg-break- md-break- sm-break-',
        breakpoints: '2',
    },
    {
        img: '../../../assets/dev-mode-home.svg',
        title: 'Dev tools',
        subtitle: 'Dive in to dev mode',
        body: 'Dev tools for integration. API and webhooks......',
        buttonLabel: 'Learn more',
        route: ROUTE_PATHS.DEVTOOLS_DASHBOARD,
        color: '#f1e9c1',
        width: 'lg-break- md-break- sm-break-',
        breakpoints: '3',
    },
];

import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@app-env';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@shared/auth/services/auth.service';
import { LoadingService } from '@shared/loading/loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    loadingSubscription!: Subscription;
    loading: boolean = false;

    constructor(
        public translate: TranslateService,
        private loadingService: LoadingService,
        private zone: NgZone,
        private cdRef: ChangeDetectorRef,
        private authService: AuthService,
    ) {
        translate.addLangs(['en-AU']);
        translate.setDefaultLang('en-AU');
    }

    ngOnInit() {
        this.loadingSubscription = this.loadingService.loadingObservable.subscribe(
            (data: boolean) => {
                this.zone.run(() => {
                    this.loading = data;
                    this.cdRef.detectChanges();
                });
            },
        );
        if (environment.loadHotjar) {
            this.loadHotjar();
        }
    }

    ngOnDestroy() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
        }
    }

    title = 'portal-ui';

    appChecksIsLoggedIn() {
        return this.authService.isLoggedIn();
    }

    private loadHotjar() {
        const script = document.createElement('script');
        script.innerHTML = `
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3795843,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;
        document.head.appendChild(script);
    }
}

/**
 * Webhooks Outbound Portal API
 * This specifies the Webhook Outbound endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { CreateWebhookOutboundRequestRequest } from './createWebhookOutboundRequestRequest';
import type { WebhookOutboundCreate } from './webhookOutboundCreate';

export class CreateWebhookOutboundRequest {
    /**
    * The type of the webhook
    */
    'type': CreateWebhookOutboundRequest.TypeEnum = CreateWebhookOutboundRequest.TypeEnum.External;
    /**
    * The subscriptions for the webhook
    */
    'subscriptions': Array<string>;
    'request': CreateWebhookOutboundRequestRequest;
    'context': WebhookOutboundCreate;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "type",
            "baseName": "type",
            "type": "CreateWebhookOutboundRequest.TypeEnum"
        },
        {
            "name": "subscriptions",
            "baseName": "subscriptions",
            "type": "Array<string>"
        },
        {
            "name": "request",
            "baseName": "request",
            "type": "CreateWebhookOutboundRequestRequest"
        },
        {
            "name": "context",
            "baseName": "context",
            "type": "WebhookOutboundCreate"
        }    ];

    static getAttributeTypeMap() {
        return CreateWebhookOutboundRequest.attributeTypeMap;
    }
}

export namespace CreateWebhookOutboundRequest {
    export enum TypeEnum {
        External = <any> 'external'
    }
}

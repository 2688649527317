<div class="flex border-1 border-round {{ borderClass }} w-full">
    <div class="border-none w-full">
        <input
            min="0"
            id="{{ id }}"
            type="{{ type }}"
            class="h-full w-full"
            [(ngModel)]="textInputData"
            (ngModelChange)="emitCustomEvent()"
            placeholder="{{ textPlaceholder }}"
            [disabled]="disableTextInput"
            pInputText
        />
    </div>
    <div class="border-none">
        <p-dropdown
            id="{{ id }}"
            [options]="units"
            [(ngModel)]="selectedUnit"
            (ngModelChange)="emitCustomEvent()"
            placeholder="{{ dropdownPlaceholder }}"
            [disabled]="disableDropdown"
        ></p-dropdown>
    </div>
</div>

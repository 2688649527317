<!-- Trigger Button - Starts -->
<ng-container>
    <button
        class="TimelineButton text-center cursor-pointer text-green-700 text-lg border-none p-1 white-space-nowrap"
        [ngClass]="{ 'border-round-lg border-1 border-gray-600 text-gray-600 px-3 py-2': showText }"
        (click)="openTimeline()"
    >
        <fa-icon [icon]="icon.init"></fa-icon>
        <span *ngIf="showText" class="ml-1 text-sm white-space-nowrap">Timeline</span>
    </button>
</ng-container>
<!-- Trigger Button - Ends -->

<p-sidebar
    styleClass="p-sidebar-{{ size }} surface-ground"
    [(visible)]="sidebarVisible"
    [position]="sidebarPosition"
    (onShow)="onOpenTimeLine()"
>
    <ng-template pTemplate="content">
        <div class="flex flex-column content-wrapper w-full min-h-full h-auto overflow-hidden">
            <div class="flex-grow-1">
                <div class="col-12" *ngIf="!loading && timelineData && timelineData.length > 0">
                    <div
                        *ngFor="let item of timelineData; let isFirst = first; let isLast = last"
                        [class.first-item]="isFirst"
                        [class.last-item]="isLast"
                        class="timeline-item p-2 pt-4 text-left"
                    >
                        <div class="timeline-item-details flex">
                            <div class="z-5">
                                <div
                                    class="flex align-content-center align-items-center justify-content-center timeline-item-details--icon border-circle mr-3"
                                    (click)="toggleContent($event, item)"
                                >
                                    <fa-icon [icon]="item.icon ?? icon.default"></fa-icon>
                                </div>
                            </div>
                            <div class="block w-full">
                                <div (click)="toggleContent($event, item)">
                                    <h1 class="m-0 p-0 flex text-base align-items-baseline" title="{{ item.id }}" [ngClass]="{ 'expandable':true, 'expanded': item.display?.isOpen }">
                                        {{ item.title || item.eventSignature }}
                                    </h1>
                                </div>
                                <div class="timeline-item-details--json w-100 text-sm {{ item?.display?.isOpen ? 'opened' : 'closed' }}">
                                    <div class="timeline-item-details--json_wrapper p-2 pb-2 w-100">
                                        <ngx-json-viewer [json]="item.message"></ngx-json-viewer>
                                    </div>
                                </div>
                                <div class="flex timeline-item-date mb-2 justify-content-start text-sm w-full" (click)="toggleContent($event, item)">
                                    <div class="date mr-3 text-gray-500 text-xs">
                                        <fa-icon [icon]="icon.date" size="lg" class="mr-1"></fa-icon>
                                        {{ item.createdAt | date : 'EEEE dd, LLLL y' }}
                                    </div>
                                    <div class="time mr-3 text-gray-500 text-xs">
                                        <fa-icon [icon]="icon.time" size="lg" class="mr-1"></fa-icon>
                                        {{ item.createdAt | date : 'HH:mm:ss' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="no_data p-4 mt-5 mb-5 border-round-2xl border-2 border-dashed border-gray-300 text-center text-gray-500 text-sm"
                    *ngIf="!loading && (!timelineData || timelineData?.length === 0)"
                >
                    No data in activities
                </div>
                <!-- Skeleton - Starts -->
                <div class="col-12" *ngIf="loading">
                    <div
                        *ngFor="let item of skeletonItems; let isFirst = first; let isLast = last"
                        [class.first-item]="isFirst"
                        [class.last-item]="isLast"
                        class="timeline-item p-2 pt-4 text-left"
                    >
                        <div class="timeline-item-details flex">
                            <div class="z-5">
                                <div class="timeline-item-details--icon border-circle mr-3"></div>
                            </div>
                            <div class="block w-100">
                                <p-skeleton width="15rem" height="2rem"></p-skeleton>
                                <p-skeleton width="7rem" height="1rem" styleClass="mt-2"></p-skeleton>
                                <div class="flex timeline-item-date mb-2 pt-2 justify-content-start">
                                    <div class="date mr-3">
                                        <p-skeleton width="2rem" height="1rem"></p-skeleton>
                                    </div>
                                    <div class="time">
                                        <p-skeleton width="2rem" height="1rem"></p-skeleton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Skeleton - Ends -->
            </div>
        </div>
    </ng-template>
</p-sidebar>

<div class="w-full mt-6">
    <div class="flex justify-content-center">
        <div class="flex w-5 p-4 justify-content-center">
            <p-skeleton width="35rem" height="20rem"></p-skeleton>
        </div>
        <div class="w-6 p-4">
            <div class="flex align-items-center border-bottom-1 border-primary-100 py-1">
                <p-skeleton shape="circle" size="2rem" styleClass="mr-1"></p-skeleton>
                <p-skeleton shape="circle" size="2rem" styleClass="mr-2"></p-skeleton>
                <p-skeleton width="10rem" height="1rem" styleClass="mr-2" borderRadius="7px"></p-skeleton>
                <p-skeleton width="3rem" height="1rem" borderRadius="7px"></p-skeleton>
            </div>
            <div class="py-4">
                <p-skeleton width="20rem" height="1.5rem" styleClass="mr-4 mb-3" borderRadius="16px"></p-skeleton>
                <p-skeleton width="10rem" height="1rem" borderRadius="16px" styleClass="mb-3"></p-skeleton>
                <p-skeleton width="10rem" height="1rem" borderRadius="16px" styleClass="mb-3"></p-skeleton>
                <p-skeleton width="10rem" height="1rem" borderRadius="16px" styleClass="mb-3"></p-skeleton>
            </div>
            <div class="grid">
                <div class="col-6">
                    <p-skeleton width="20rem" height="2rem" styleClass="mr-4 mb-3" borderRadius="16px"></p-skeleton>
                </div>
                <div class="col-6">
                    <p-skeleton width="20rem" height="2rem" styleClass="mr-4 mb-3" borderRadius="16px"></p-skeleton>
                </div>
            </div>
            <p-skeleton height="2rem" borderRadius="16px"></p-skeleton>
        </div>
    </div>
</div>

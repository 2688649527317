/**
 * Groups Portal API
 * Group endpoints on the Backend-For-Frontend (BFF) API for the Customer Portal
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { RequestFile } from './models';
import type { DocumentGroupPriority } from './documentGroupPriority';
import type { DocumentGroupStatus } from './documentGroupStatus';

export class DocumentGroupCreateResponse {
    'id': string;
    'name': string;
    'status'?: DocumentGroupStatus;
    'priority'?: DocumentGroupPriority;

    static discriminator: string | undefined = undefined;

    static attributeTypeMap: Array<{name: string, baseName: string, type: string}> = [
        {
            "name": "id",
            "baseName": "id",
            "type": "string"
        },
        {
            "name": "name",
            "baseName": "name",
            "type": "string"
        },
        {
            "name": "status",
            "baseName": "status",
            "type": "DocumentGroupStatus"
        },
        {
            "name": "priority",
            "baseName": "priority",
            "type": "DocumentGroupPriority"
        }    ];

    static getAttributeTypeMap() {
        return DocumentGroupCreateResponse.attributeTypeMap;
    }
}

export namespace DocumentGroupCreateResponse {
}

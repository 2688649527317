import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    loadingSubject;
    loadingObservable;
    loadingCounter: number = 0;

    constructor() {
        this.loadingSubject = new BehaviorSubject<boolean>(false);
        this.loadingObservable = this.loadingSubject.asObservable();
    }

    startLoading() {
        if (this.loadingCounter === 0) {
            this.loadingSubject.next(true);
        }
        this.loadingCounter++;
    }

    finishLoading() {
        this.loadingCounter--;
        if (this.loadingCounter === 0) {
            this.loadingSubject.next(false);
        }
    }

    isInCreateMode(fullPath: string): boolean {
        const path = new URL(fullPath, window.location.origin).pathname;
        return path.includes('/create');
    }
}

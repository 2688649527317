import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faGripLines } from '@fsco/fontawesome-pro/pro-light-svg-icons';
import { LocalStorageService } from '@shared/local-storage/local-storage.service';
import { MenuStateService } from '@shared/services/menu-state.service';
import { Subscription } from 'rxjs';
import { Submenu } from '../sub-menu/sub-menu-model';
import { SubMenuService } from '../sub-menu/sub-menu.service';
import { MainMenu, MenuItem } from './main-menu.model';
import { LayoutService } from '@shared/services/layout.service';
@Component({
    selector: 'fsco-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
    isLoggedIn = false;
    isMenuExpanded = true;
    activeMenuItem: MenuItem | null = null;
    subMenuActived = false;
    subMenuExpanded = true;
    topBarExpanded = false;

    submenuSubscription: Subscription;
    layoutSubscription!: Subscription;

    icons = {
        collapse: faGripLines,
    };

    @Input() menu!: MainMenu;
    activeTab = 1;
    constructor(
        private cdRef: ChangeDetectorRef,
        private subMenu: SubMenuService,
        private localStorageService: LocalStorageService,
        private menuStateService: MenuStateService,
        private layoutService: LayoutService
    ) {
        this.submenuSubscription = new Subscription();
    }

    ngOnInit() {
        this.submenuSubscription = this.subMenu.currentSubmenu.subscribe((submenu: Submenu) => {
            this.subMenuActived = submenu.items && submenu.items.length > 0;
            this.cdRef.detectChanges();
        });

        this.layoutSubscription = this.layoutService.showTopBar$.subscribe(status => {
            this.topBarExpanded = status;
        });

        this.menuStateService.menuExpanded$.subscribe((isExpanded) => {
            this.isMenuExpanded = isExpanded;
        });
    }

    ngOnDestroy() {
        if (this.submenuSubscription) this.submenuSubscription.unsubscribe();
        if (this.layoutSubscription) this.layoutSubscription.unsubscribe();
    }

    toggleMenu() {
        this.isMenuExpanded = !this.isMenuExpanded;
        this.localStorageService.setMenuExpandedState(this.isMenuExpanded);
        this.menuStateService.setMenuExpanded(this.isMenuExpanded);
    }

    onSubmenuToggle(isExpanded: boolean) {
        this.subMenuExpanded = isExpanded;
        this.cdRef.detectChanges();
    }
}

<div class="login-hiiro flex align-items-center justify-content-center h-screen">
    <div class="flex flex-column justify-content-center align-items-center text-center">
        <div class="login-hiiro-logo border-"></div>
        <div class="login-hiiro-message text-gray-500 mt-2">
            <div *ngIf="!appChecksHasUserData(); else gettingUserData">
                <span class="text-gray-700">Loading</span> user data<span class="dots"></span>
            </div>
            <ng-template #gettingUserData>
                <span class="text-gray-700">Redirecting</span> to login<span class="dots"></span>
            </ng-template>
        </div>
    </div>
</div>
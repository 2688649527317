import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    faFileLines,
    faGearCode,
    faHome,
    faUnlock,
    faUser,
    faWallet,
} from '@fortawesome/pro-regular-svg-icons';
import { Header } from '@fsco-components/page-structure/header/header.model';
import { MainMenu, MenuItem } from '@fsco-components/page-structure/main-menu/main-menu.model';
import { WebsocketMessage } from '@portal-api-models/websocketMessage';
import { AuthService } from '@shared/auth/services/auth.service';
import { LoggerService } from '@shared/log.service';
import { ROUTE_PATHS } from '@shared/navigation.service';
import { FSCO } from '@shared/notifications';
import { WebSocketService } from '@shared/notifications/websocket.service';
import { SessionService } from '@shared/session.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from '@app-env';
@Component({
    selector: 'cp-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
    private notificationsSubscription!: Subscription;

    constructor(
        private logger: LoggerService,
        private authService: AuthService,
        private sessionService: SessionService,
        private router: Router,
        private messageService: MessageService,
        private webSocketService: WebSocketService,
    ) {
        this.logger.setContext(NavComponent.name);

        // todo: tmp feature toggle for wallets - remove later
        if (environment.featureWallets) {
            this.mainMenu.top!.items!.push(
                new MenuItem({
                    icon: faWallet,
                    label: 'page.menu.wallets',
                    link: ROUTE_PATHS.WALLET_LIST,
                }),
            );
        }
    }

    ngOnInit(): void {
        this.getGlobalNotifications();
    }

    ngOnDestroy(): void {
        if (this.notificationsSubscription) {
            this.notificationsSubscription.unsubscribe();
        }
    }

    mainMenu: MainMenu = {
        top: {
            items: [
                new MenuItem({
                    icon: faHome,
                    label: 'page.menu.home',
                    link: '/',
                    exactMatch: true,
                }),
                new MenuItem({
                    icon: faFileLines,
                    label: 'page.menu.documents',
                    link: ROUTE_PATHS.DOCS_DASHBOARD,
                }),
                new MenuItem({
                    icon: faGearCode,
                    label: 'page.menu.devtools',
                    link: ROUTE_PATHS.DEVTOOLS_DASHBOARD,
                }),
            ],
            styles: {
                default: {
                    textColor: 'main-menu-text-color-primary',
                    iconColor: 'main-menu-icon-color-primary',
                },
                hover: {
                    iconBackgroundColor: 'main-menu-icon-bg-color-primary',
                },
                active: {
                    backgroundColor: 'main-menu-item-bg-color-primary',
                },
            },
        },
        bottom: {
            items: [
                new MenuItem({
                    icon: faUser,
                    label: 'page.menu.settings',
                    link: '/settings',
                    exactMatch: true,
                }),
                // new MenuItem({ icon: faWallet, label: 'Wallet', link: '/me/wallet', exactMatch: true }),
            ],
            styles: {
                default: {
                    textColor: 'main-menu-text-color-primary',
                    iconColor: 'main-menu-icon-color-primary',
                },
                hover: {
                    iconBackgroundColor: 'main-menu-icon-bg-color-primary',
                },
                active: {
                    backgroundColor: 'main-menu-item-bg-color-primary',
                },
            },
        },
    };

    header: Header = {
        title: 'FSCO',
        styles: {
            default: {
                backgroundColor: 'header-bg-color-primary',
                textColor: 'header-text-color-primary',
                iconColor: 'header-icon-color-primary',
                iconBackgroundColor: 'header-icon-bg-color-primary',
            },
        },
        actions: [
            // {
            //     id: 'search',
            //     icon: faSearch,
            //     type: 'icon',
            // },
            // {
            //     id: 'notifications',
            //     icon: faBell,
            //     type: 'icon',
            // },
            // {
            //     id: 'user',
            //     icon: faUser,
            //     type: 'icon',
            // },
            {
                id: 'logout',
                label: 'page.menu.logout',
                type: 'link',
                icon: faUnlock,
            },
        ],
    };

    async handleAction(actionId: string) {
        switch (actionId) {
            case 'search':
                this.searchClicked();
                break;
            case 'notifications':
                this.notificationsClicked();
                break;
            case 'user':
                this.userClicked();
                break;
            case 'logout':
                await this.logout();
                break;
        }
    }

    searchClicked() {
        this.logger.debug('search clicked');
    }

    notificationsClicked() {
        this.logger.debug('notifications clicked');
    }
    userClicked() {
        this.logger.debug('user clicked');
    }

    async logout(): Promise<void> {
        this.sessionService.clear();
        this.router.navigateByUrl('/logout');
    }

    switchOrganisation() {
        this.authService.redirectToLogin();
    }

    isSuperUser() {
        return false;
    }

    getGlobalNotifications(): void {
        this.notificationsSubscription = this.webSocketService
            .getMessagesByEventTypes([
                FSCO.MarketPlace.LISTING_SOLD,
                FSCO.MarketPlace.LISTING_BOUGHT,
                FSCO.Asset.ASSET_MINTED,
                FSCO.Asset.ASSET_RETIRED,
                FSCO.Asset.ASSET_TRANSFERRED,
            ])
            .subscribe({
                next: (message: WebsocketMessage) => {
                    let displayMessage = '';
                    switch (message.externalEventType) {
                        case FSCO.MarketPlace.LISTING_BOUGHT:
                            displayMessage =
                                'Your new asset has been purchased and is now available in your wallet.';
                            break;
                        case FSCO.MarketPlace.LISTING_SOLD:
                            displayMessage =
                                'Your asset has been sold. Funds have been deposited into your wallet.';
                            break;
                        case FSCO.Asset.ASSET_MINTED:
                            displayMessage =
                                'Your new asset has been minted and is now available in your wallet.';
                            break;
                        case FSCO.Asset.ASSET_RETIRED:
                            displayMessage =
                                'Your asset has been retired and removed from your wallet.';
                            break;
                        case FSCO.Asset.ASSET_TRANSFERRED:
                            displayMessage = 'Your new asset has been transferred successfully.';
                            break;
                    }
                    if (displayMessage != '') {
                        this.messageService.add({
                            severity: 'info',
                            summary: 'Info',
                            detail: displayMessage,
                            sticky: true,
                        });
                    }
                },
            });
    }
}
